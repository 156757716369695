import styled from 'styled-components';

export const Popup = styled.div`
    display:flex;
    position:absolute;
    top:100%;
    left:0;
    background-color:#AAC962;
    box-shadow: 0px 3px 6px #00000029;
    padding:15px;
    z-index:9999;
`;

export const List = styled.ul`
    margin:0;
    padding:0;
    list-style-type:none;
`;

export const Item = styled.li`
    display:flex;
    justify-content:flex-start;
`;