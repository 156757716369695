import React from 'react';
import styled from 'styled-components';
import { 
    bodyColor, applyBorderPadding, borderPadding, mobileBoundryWidth, borderPaddingMobile 
} from '../../themes';
import { Image, Title, Subtitle} from './component-fields';


const IMAGE_WIDTH = 150;
const IMAGE_WIDTH_MOBILE = 87;
const headerTitleTop = ({theme}) => 2 * theme.borderPadding;
const headerTitleTopMobile = ({theme}) => 3 * theme.borderPaddingMobile;
const headerTitleLeft = ({theme}) => 2 * theme.borderPadding + IMAGE_WIDTH;
const headerTitleLeftMobile = ({theme}) => 2 * theme.borderPaddingMobile + IMAGE_WIDTH_MOBILE;

const DetailHeader = styled( ({image, title, subtitle, children, ...props}) => {
    return (
        <div {...props}>
            <Image src={image} alt={`${title}`} />
            <title-type-container>
                <Title> {title} </Title>
                <Subtitle> {subtitle} </Subtitle>
            </title-type-container>
            {children}
        </div>
    );
})`
    position: relative;
    display: flex;
    background-color: ${bodyColor};
    flex-wrap: wrap;


    ${applyBorderPadding}

    ${Image} {
        width: ${IMAGE_WIDTH}px;
        height: ${IMAGE_WIDTH}px;

        margin-top: ${headerTitleTop}px;

        @media (max-width: ${mobileBoundryWidth}px) {
            width: ${IMAGE_WIDTH_MOBILE}px;
            height: ${IMAGE_WIDTH_MOBILE}px;

            margin-top: ${headerTitleTopMobile}px;
        }
    }

    title-type-container {
        margin-left: ${borderPadding}px;
        margin-top: ${headerTitleTop}px;

        height: ${IMAGE_WIDTH}px;

        flex-basis: calc(100% - ${headerTitleLeft}px);

        @media (max-width: ${mobileBoundryWidth}px) {
            margin-left: ${borderPaddingMobile}px;
            margin-top: ${headerTitleTopMobile}px;

            height: ${IMAGE_WIDTH_MOBILE}px;

            flex-basis: calc(100% - ${headerTitleLeftMobile}px);
        }
    }
`;

export default DetailHeader;
export { 
    CloseButton, 
    Image, 
    Title, 
    Subtitle
} from './component-fields';
