import styled from "styled-components";
import React, { useContext } from "react";
import { ClockIcon, DownArrowIcon } from "../icons";
import { Field } from "./field";
import { ShowPopUp } from "../show-pop-up";
import { Description, HoursMenu, Status } from "./components";
import { FieldCacheContext } from ".";
import { WEEKDAYS } from '../../utils/constants'


const weekdayAsSeconds = (weekday, date) => {
    let weekdayIndex = (date || new Date()).getDay() - 1;
    if (weekdayIndex < 0) {
        weekdayIndex = 6;
    }

    let weekdaysValues = { 'mon': 0, 'tue': 0, 'wed': 0, 'thu': 0, 'fri': 0, 'sat': 0, 'sun': 0 };
    for (let i = 0; i < WEEKDAYS.length; i++) {
        weekdaysValues[WEEKDAYS[weekdayIndex]] = i * 86400000;
        weekdayIndex++;
        if (weekdayIndex > 6) weekdayIndex = 0;
    }
    return (weekdaysValues[weekday]);
}

const timeAsSeconds = (time = "00:00") => {
    const timeSplit = time.split(':');
    return [parseInt(timeSplit[0]) * 3600000, parseInt(timeSplit[1]) * 60000];
}

const getLocalDate = (date) => {
    const time = date.getTime()
    date.setTime(time - (date.getTimezoneOffset() * 60 * 1000));
    const day = date.getDate();
    date.setTime(time);
    return day;
}

const getHoursAsDates = (hours, baseDate) => {
    return hours.map( ({from, to, weekday}) => {
        const weekdaySeconds = weekdayAsSeconds(weekday, baseDate);
        const [fromHourSeconds, fromMinuteSeconds] = timeAsSeconds(from);
        const [toHourSeconds, toMinuteSeconds] = timeAsSeconds(to);
        return ({
            from: new Date( baseDate.getTime() + weekdaySeconds + fromHourSeconds + fromMinuteSeconds ), 
            to: new Date( baseDate.getTime() + weekdaySeconds + toHourSeconds + toMinuteSeconds )
        });
    }).sort( ({from: from1}, {from: from2}) => from1 - from2 );
}

export const formatOperatingHoursDescription = (hours = [], { useRelativeDateFormatting = false, date: optionalDate }) => {
    const date = optionalDate || new Date();
    
    const baseDate = new Date(date);
    baseDate.setHours(0, 0, 0, 0);
    baseDate.setDate( date.getDate() - date.getDay());

    const hoursAsDates = getHoursAsDates(hours, baseDate);
    const isOpen = hoursAsDates.some( ({from, to}) => date >= from && date < to );
    const formattedStatus = isOpen ? 'Open' : 'Closed';
    const moment = require('moment');
    
    let nextOpenDayRange = hoursAsDates.find( ({from}) => {
        return date.getTime() - from.getTime() <= 0 
    });
    
    let isNextWeek = false;
    if (!nextOpenDayRange) {
        //Add one week to the first range dates.
        isNextWeek = true;
        nextOpenDayRange = hoursAsDates[0];
    }

    let isOtherDay = true;
    let nextFirstOpenIsTomorrow = false;
    let formattedNextDay = null;
    if (nextOpenDayRange) {
        isOtherDay = getLocalDate(nextOpenDayRange.from) !== getLocalDate(date);
        nextFirstOpenIsTomorrow = ((getLocalDate(nextOpenDayRange.from) - getLocalDate(date)) === 1) 
        
        if (!isOpen && !isOtherDay && useRelativeDateFormatting) {
            formattedNextDay = 'Today';
        }
        else if (nextFirstOpenIsTomorrow && useRelativeDateFormatting) {
            formattedNextDay = 'Tomorrow';
        }
        else {
            formattedNextDay = moment(nextOpenDayRange.from).format('dddd');
        }
    }

    //Find all ranges of that day;
    let allNextOpenDayRanges = hoursAsDates.reduce( (dayRanges, range) => {
        if (nextOpenDayRange && getLocalDate(nextOpenDayRange.from) === getLocalDate(range.from) ) {
            dayRanges.push(range);
        }
        return dayRanges;
    }, []);

    if (isNextWeek) {
        allNextOpenDayRanges = allNextOpenDayRanges.map( ({from, to}) => ({
            from: new Date(from.getTime() + 604800000),
            to: new Date(to.getTime() + 604800000)
        }) );
    }
    
    const formatedAllNextOpenDayRanges = allNextOpenDayRanges.map( ({from, to}) => 
        `${moment(from).format('LT')} - ${moment(to).format('LT')}` 
    );

    return {
        isOpen,
        nextFirstOpenIsTomorrow,
        formattedStatus,
        isNextWeek,
        isOtherDay,
        hoursAsDates,
        allNextOpenDayRanges,
        formatedAllNextOpenDayRanges,
        formattedNextDay,
    };
}



export const BusinessOperatingHours = styled(({ className, children, hours, cache}) => {
    //Recover know fields from cache.
    const _cache = useContext(FieldCacheContext) || cache || {};
    if (!_cache.formatOperatingHoursDescription) {
        _cache.formatOperatingHoursDescription = formatOperatingHoursDescription(hours, {
            useRelativeDateFormatting: true,
        });
    }

    const {
        isOpen,
        formattedNextDay,
        formattedStatus,
        formatedAllNextOpenDayRanges
    } = _cache.formatOperatingHoursDescription;
    const hasHours = (hours && hours.length > 0);

    return (
        <ShowPopUp
            PopUpComponent={ () => <HoursMenu hours={hours} /> }
            className={className} 
        >
            <Field>
                <ClockIcon bold={true}/>
                <Status open={isOpen}>{formattedStatus}</Status>
                <Description>
                    {!hasHours ?
                        'Not Open This Week'
                        :
                        (!isOpen ?
                            `Opens ${formattedNextDay || 'At Unkown Time'}` 
                            :
                            //TODO: Add styling for when there are more than one ranges in one day
                            formatedAllNextOpenDayRanges.join(', ')
                        )
                    }
                </Description>
                {hasHours && <DownArrowIcon />}
                {children}
            </Field>
        </ShowPopUp>
    );
})`
    span {
        margin-top: 1px;
    }
`;