import React from 'react';
import styled from "styled-components";
import Icons from '../../../utils/social-contact-icons';
import {Popup, List, Item} from './popup-with-items';

const ContactLink = styled.a`
    text-decoration:none;
    display:flex;
    align-items:center;
    color:black;
`

export const ContactInfoMenu = ({ contactInfo }) => {
    if (!contactInfo.length) return null;
    return (
        <Popup>
            <List>
                {contactInfo.map(({ type, number, /*isDefault,*/ }, i) => {
                    const Icon = Icons[type] || Icons.mobile;
                    return (
                        <Item key={`contact-ifo-item-${i}`}>
                            <ContactLink href={`tel:${number}`}>
                                <Icon /> {number}
                            </ContactLink>
                        </Item>
                    )
                })}
            </List>
        </Popup>
    )
}