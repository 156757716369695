import React from 'react';
import ScreenContainer from "../../components/screen-container";
import { Helmet } from "react-helmet";

//Lazy load firebase.
const getFirebase = () => {
    const {getFirebase} = require('../../utils/data');
    require('firebase/auth');
    return getFirebase();
}

//Lazy load the ui.
var _StyledFirebaseAuth = null;
const loadStyledFirebaseAuth = () => {
    if (!_StyledFirebaseAuth) {
        const {StyledFirebaseAuth} = require('react-firebaseui');
        _StyledFirebaseAuth = StyledFirebaseAuth;
    }
    return _StyledFirebaseAuth;
}

const LoginScreen = () => {
    const StyledFirebaseAuth = loadStyledFirebaseAuth();
    const firebase = getFirebase();

    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
    };
    
    return (
        <ScreenContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mi Kier Come - Login</title>
                <link rel="canonical" href="https://mikiertraha.com/login" />
            </Helmet>
            <StyledFirebaseAuth 
                uiConfig={uiConfig} 
                firebaseAuth={firebase.auth()} 
            />
        </ScreenContainer>
    )
};
export default LoginScreen;
