import React from 'react';
import styled from "styled-components";
import BusinessItem from '../business-item';
import {AddItem, EmptyListItem} from './component-fields';

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    justify-content:center;
`;

const BusinessListings = styled( ({ 
        listings = [], 
        canAddItem = false, 
        canEditItem = false, 
        canDeleteItem = false, 
        onEditItem, 
        onDeleteItem, 
        onClickItem, 
        ...props
}) => {

    return (
        <Container {...props} >
            {canAddItem && <AddItem />}
            {listings.map((business) =>
                <BusinessItem
                    key={business.id}
                    {...business}
                    onDeleteItem={() => onDeleteItem(business)}
                    onEditItem={() => onEditItem(business)}
                    onClickItem={() => onClickItem(business)}
                    canEditItem={canEditItem}
                    canDeleteItem={canDeleteItem}
                    cache={business}
                />
            )}
            {listings.length === 0 && 
                <EmptyListItem />
            }
        </Container>
    );
})``;
export default BusinessListings;