import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components";

const PlaceholderMenuPage = {
    url: ""
}

const MenuContainer = styled.div`
    padding-top: 5px;
    background-color: white;
`;

const SelectedMenuPageImage = styled.img`
    width: 100%;
    flex-basis: 750px;
    max-width: 750px;
    height: auto;
    max-height: 750px;
    object-fit: contain;
`;

const SelectedMenuPage = styled(({ url, ...props }) => {
    const imageReference = useRef();
    return (
        <div {...props}>
            <SelectedMenuPageImage
                ref={imageReference} 
                src={url} 
            />
        </div>
    )
})`
    display: flex;
    justify-content: center;
`;

const MenuPages = styled.div` 
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    justify-content: center;

    @media(max-width:480px) {
        justify-content: space-evenly safe;
    }

`;

const ThumbnailMenuPageImage = styled.img`
    width: 165px;
    height: auto;
    border: ${({ selected }) => selected ? '#89D5CB ' : 'white'} solid 2px;
    margin: calc( 0.5 * ${({ theme }) => theme.borderPadding});
    cursor:pointer;
    
    @media (max-width: ${({ theme }) => theme.mobileBoundryWidth}px) {
        margin: calc( 0.5 * ${({ theme }) => theme.borderPaddingMobile}px);
    }
`;

const MenuPage = styled(({ url, selected = true, onSelect, ...props }) => {
    const imageReference = useRef();
    return (
        <div {...props}>
            <ThumbnailMenuPageImage
                ref={imageReference}
                src={url}
                selected={selected}
                onClick={() => (onSelect && onSelect())}
            />
        </div>
    )
})``;

export const Menu = styled( ({menu, ...props}) => {
    const [storedMenu, setStoredMenu] = useState(menu);
    const [selectedMenuPage, setSelectedMenuPage] = useState(PlaceholderMenuPage);

    useEffect( () => {
        if (JSON.stringify(storedMenu) !== JSON.stringify(menu)) {
            setStoredMenu(menu);
            setSelectedMenuPage(menu[0] ?? PlaceholderMenuPage);    
        }
    }, [menu, storedMenu]);

    const selectMenuPage = (page) => {
        if (storedMenu.includes(page)) {
            setSelectedMenuPage(page);
        }
    }
    return (
        <MenuContainer {...props}>
            <SelectedMenuPage url={selectedMenuPage.url} />
            <MenuPages>
                {(storedMenu.length > 0) && storedMenu.map((page, index) =>
                    <MenuPage
                        key={index}
                        url={page.url}
                        onSelect={() => selectMenuPage(page)}
                        selected={page === selectedMenuPage}
                    />
                )}
            </MenuPages>
        </MenuContainer>
    )
})``;