import React, { useContext } from 'react';
import styled from "styled-components";
import { Field } from './field';
import { StoreIcon } from '../icons';
import { FieldCacheContext } from '.';
import { businessTypeOptions } from '../../utils/constants';

export const formatBusinessTypeDescription = (type = '') => {
    const businessType = businessTypeOptions.find(({ key }) => key === type);
    if (businessType) {
        return businessType.text;
    }
    return 'other';
}

export const BusinessType = styled(({ className, children, type }) => {
    //Recover know fields from cache.
    const cache = useContext(FieldCacheContext) || {};
    if (!cache.formatBusinessTypeDescription) {
        cache.formatBusinessTypeDescription = formatBusinessTypeDescription(type);
    }

    return (
        <Field className={className}>
            <StoreIcon />
            {cache.formatBusinessTypeDescription}
            {children}
        </Field>
    );
})``;