import React from 'react';
import styled from 'styled-components';
import ScreenContainer from '../../components/screen-container';
import { TextField, Select, /*SelectValidator,*/ ValueSetValidator, EmailValidator } from '../../components/input';
import { businessTypeOptions, cuisineOptions, paymentMethodOptions, listingStatus } from '../../utils/constants';
import { getFirebase, useSaveBusinessItemData, generateUniqueBusinessesID } from '../../utils/data';
import { urlify } from '../../utils/format';
import MultiSelect from './multi-select';
import LocationItem from './location-item';
import OpeningHoursItem from './opening-hours-item';
import ContactItem from './contact-item';
import ServiceItem from './service-item';
import SocialMediaItem from './social-media-item';
import MenuPageItem from './menu-page-item';
import { ArrowDownIcon } from '../../components/icons';
import TagsSection from '../../components/tags-section';
import TextArea from '../../components/input/TextArea';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    margin:15px;
    background-color:lightgrey;
    border:1px solid #f5f5f5;
    padding:30px;

    @media(max-width:800px) {
        flex:100%;
        flex-direction:column;
    }

    @media(max-width:650px) {
        padding:15px;
    }
`;

const LeftColumn = styled.div`
    flex: 80%;

    @media(max-width:800px) {
        flex:100%;
    }
`;

const RightColumn = styled.div`
    position:sticky;
    top:30px;
    margin-left:30px;
    flex: 20%;
    background-color:#f5f5f5;
    box-shadow:0px 3px 6px #00000029;
    padding:15px;

    @media(max-width:800px) {
        flex:100%;
        position:relative;
        margin-left:0;
        width:100%;
        top:0;
    }
`;

const Title = styled.h2`
    text-transform:uppercase;
    color:#e25b45;
    font-size:28px;
    line-height:38px;
    letter-spacing:1.8px;
    margin-bottom:15px;
    text-align:left;
    margin-top:0px;

    @media(max-width:650px) {
        font-size:21px;
        
    }
`;

const SubTitle = styled.h3`
    text-transform:uppercase;
    color:#e25b45;
    font-size:21px;
    line-height:31px;
    letter-spacing:1.8px;
    margin-bottom:15px;
    text-align:left;
    margin-top:0px;
    width:100%;
    display:flex;

    span {
        font-size:13px;
        flex-grow: 1;
        text-align: right;
        cursor:pointer;
        
        &:hover {
            color:#262626;
            text-decoration:underline;
        }
    }

    @media(max-width:650px) {
        letter-spacing:1.1px;
    }
`;

const SubContainer = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    background-color:${props => [props.bgColor]};
    padding:${props => [props.padding]};

    @media(max-width:650px) {
        flex-direction:column;
    }
`;

const FormItem = styled.div`
    margin-bottom:15px;
    width:calc( (100% - 30px ) / 2 );
    margin-right:15px;

    &.fullColumn {
        width:calc( 100% );
        margin-right:0;
    }

    @media(max-width:650px) {
        width:100%;
        margin-right:0px;
    }

    :nth-child(2n+0) {
        margin-left:15px;
        margin-right:0;

        @media(max-width:650px) {
            margin-left:0;
            margin-right:0;
        }
    }

    .Error {
        border:1px solid #990000;
        background-color:rgba(153,0,0,0.3);
    }
    span.Error {
        display:none;
        visibility:hidden;
    }

    input, textarea {
        width:100%;
        height:35px;
        padding-left:10px;
        font-size:12px;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:0px;
        box-shadow:none;
        -webkit-appearance: none;
    }
    select {
        width:100%;
        height:35px;
        font-size:12px;
    }
    textarea {
        height:200px;
        padding:10px;
        border:1px solid rgba(0,0,0,0.1);
        
    }
    :last-child {
    }
`;

const Label = styled.label`
    font-size:12px;
    line-height:26px;
    font-weight:600;
    margin-bottom:8px;
    text-transform:uppercase;
    display:block;
`;

const Button = styled.button`
    margin-top:15px;
    border:1px solid rgba(255,255,255,0.5);
    background-color:#89d5cb;
    color:#262626;
    padding:15px 60px;
    cursor:pointer;
    font-size:12px;
    font-weight:700;
    line-height:21px;
    text-transform:uppercase;
    width:100%;
    text-align:center;
    text-decoration:none;
    transition:all 0.2s linear;

    &:hover {
        background-color:#aac962;
    }
`;

const Divider = styled.div`
    border-bottom:1px solid rgba(255,255,255,0.5);
    padding-bottom:30px;
    margin-bottom:30px;
    width:100%;
    height:1px;
`;

const JumpLink = styled.a`
    position:fixed;
    top:40px;
    z-index:101;
    right:15px;
    display:none;

    @media(max-width:800px) {
        display:block;
    }
`;

export default class AddOrUpdateBusinessItemScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emailAddress: '',
            locations: [{}],
            name: '',
            operatingHours: [],
            type: '',
            website: '',
            contactOptions: [],
            serviceOptions: [],
            cuisines: [],
            paymentMethods: [],
            menu: [{ url: '' }],
            logo: 'https://storage.googleapis.com/mikiercome-961bb.appspot.com/logos/mkc.png',
            socialMedia: [],
            status: 'draft',
            tags: [],
            aboutTitle: 'About',
            aboutDescription: '',
            orderTitle: 'Order',
            orderDescription: '',
            deliveryTitle: 'Delivery',
            deliveryDescription: '',
        };
    }

    componentDidMount() {
        let permalink = ((this.props.match || {}).params || {}).permalink || undefined;
        if (permalink) {
            this.getBusinessItemData(permalink);
        } else {
            this.setState({ id: generateUniqueBusinessesID() });
        }
    }
    onChange = ({ target }) => this.setState({ [target.id]: target.value })

    onArrayChange = (id, value, index) => {
        let arrayProp = this.state[id];
        arrayProp[index] = value;
        this.setState({ [id]: arrayProp })
    }

    onArrayItemDelete = (id, index) => {
        let arrayProp = this.state[id];
        arrayProp.splice(index, 1);
        this.setState({ [id]: arrayProp });
    }

    onMultiSelectChange = (id, e) => {
        const { id: targetId, checked } = e.target;
        let selected = this.state[id];
        if (checked) {
            selected.push(targetId);
        } else {
            var index = selected.indexOf(targetId);
            if (index > -1) {
                selected.splice(index, 1);
            }
        }
        this.setState({ [id]: selected });
    }

    save = () => {
        let { setLoading, error, id, ...businessItem } = this.state;
        businessItem.permalink = urlify(businessItem.name);

        error = useSaveBusinessItemData(id, businessItem);
        if (error) {
            this.setState({ error });
        }
    }

    getBusinessItemData(permalink) {
        const self = this;
        require('firebase/firestore');
        getFirebase().firestore().collection('businesses').where('permalink', '==', permalink).limit(1).get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    if (doc.exists) {
                        if (!doc.exists) return null;
                        self.setState({
                            id: doc.id,
                            ...doc.data()
                        });
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const {
            emailAddress,
            locations,
            name,
            operatingHours,
            type,
            website,
            contactOptions,
            serviceOptions,
            cuisines,
            paymentMethods,
            menu,
            logo,
            socialMedia,
            status,
            tags,
            aboutTitle,
            aboutDescription,
            orderTitle,
            orderDescription,
            deliveryTitle,
            deliveryDescription,
        } = this.state;
        const { showFormSuccess, registrationLoading } = this.props;

        let sendStatus = 'initial';
        if (registrationLoading) sendStatus = 'processing';
        else if (showFormSuccess) sendStatus = 'done';

        return (
            <ScreenContainer>
                <JumpLink href="#bottom">
                    <ArrowDownIcon />
                </JumpLink>
                <Container>
                    <LeftColumn>
                        <Title>Add a new business listing</Title>
                        <SubContainer>
                            <FormItem>
                                <Label htmlFor="name">Company name *</Label>
                                <TextField
                                    id='name'
                                    value={name}
                                    onChange={this.onChange}
                                    validator={new ValueSetValidator(this.validator)}
                                />
                            </FormItem>
                            <FormItem >
                                <Label htmlFor="type">Type of Business *</Label>
                                <Select
                                    id="type"
                                    value={type}
                                    onChange={({ target }) => this.onChange({ target: { id: 'type', value: target.value } })}
                                    options={businessTypeOptions.map(({ key, text }) => ({
                                        key: key,
                                        value: key,
                                        text: `${text}`,
                                    }))}
                                />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="emailAddress">Company Email *</Label>
                                <TextField
                                    id='emailAddress'
                                    value={emailAddress} onChange={this.onChange}
                                    validator={new EmailValidator(this.validator)}
                                />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="website">Company website</Label>
                                <TextField id='website' value={website} onChange={this.onChange} />
                            </FormItem>
                        </SubContainer>
                        <Divider />
                        <Title>Company description</Title>
                        <SubContainer>
                            <FormItem>
                                <Label htmlFor="aboutTitle">About Title</Label>
                                <TextField id='aboutTitle' value={aboutTitle} onChange={this.onChange} />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="orderTitle">Order title</Label>
                                <TextField id='orderTitle' value={orderTitle} onChange={this.onChange} />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="aboutDescription">About description</Label>
                                <TextArea id="aboutDescription" value={aboutDescription} onChange={this.onChange} />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="orderDescription">Order description</Label>
                                <TextArea id="orderDescription" value={orderDescription} onChange={this.onChange} />
                            </FormItem>
                            <FormItem>
                                <Label htmlFor="deliveryTitle">Delivery title</Label>
                                <TextField id='deliveryTitle' value={deliveryTitle} onChange={this.onChange} />
                            </FormItem>
                            <FormItem />
                            <FormItem>
                                <Label htmlFor="deliveryDescription">Delivery description</Label>
                                <TextArea id="deliveryDescription" value={deliveryDescription} onChange={this.onChange} />
                            </FormItem>
                        </SubContainer>
                        <Divider />
                        <SubTitle>
                            Locations
                            <span onClick={() => { locations.push([]); this.setState({ locations }) }}>+ add new location</span>
                        </SubTitle>
                        {locations.length > 0 && locations.map((location, i) =>
                            <LocationItem key={`location-item-${i}`} propertyId='locations' index={i} {...location} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>
                            Contact info
                            <span onClick={() => { contactOptions.push([]); this.setState({ contactOptions }) }}>+ add Contact info</span>
                        </SubTitle>
                        {contactOptions.length > 0 && contactOptions.map((contact, i) =>
                            <ContactItem key={`contact-item-${i}`} propertyId='contactOptions' index={i} {...contact} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>
                            Social media
                            <span onClick={() => { socialMedia.push([]); this.setState({ socialMedia }) }}>+ add Social media</span>
                        </SubTitle>
                        {socialMedia.length > 0 && socialMedia.map((contact, i) =>
                            <SocialMediaItem key={`social-media-item-${i}`} propertyId='socialMedia' index={i} {...contact} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>
                            operating hours
                            <span onClick={() => { operatingHours.push([]); this.setState({ operatingHours }) }}>+ add operating hours</span>
                        </SubTitle>
                        {operatingHours.length > 0 && operatingHours.map((hours, i) =>
                            <OpeningHoursItem key={`operating-hours-item-${i}`} propertyId='operatingHours' index={i} {...hours} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>Cuisines</SubTitle>
                        <MultiSelect options={cuisineOptions} selected={cuisines} toggleCheckBox={this.onMultiSelectChange} propertyId='cuisines' />
                        <Divider />
                        <SubTitle>Tags</SubTitle>
                        <TagsSection selected={tags} propertyId='tags' onChange={this.onChange} deleteArrayItem={this.onArrayItemDelete} />
                        <Divider />
                        <SubTitle>
                            Menu pages
                            <span onClick={() => { menu.push([]); this.setState({ menu }) }}>+ add new menu page</span>
                        </SubTitle>
                        {menu.length > 0 && menu.map((menuPage, i) =>
                            <MenuPageItem key={`menu-page-item-${i}`} propertyId='menu' index={i} {...menuPage} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>
                            Services
                            <span onClick={() => { serviceOptions.push([]); this.setState({ serviceOptions }) }}>+ add new service</span>
                        </SubTitle>
                        {serviceOptions.length > 0 && serviceOptions.map((service, i) =>
                            <ServiceItem key={`service-item-${i}`} propertyId='serviceOptions' index={i} {...service} onArrayItemChange={this.onArrayChange} deleteArrayItem={this.onArrayItemDelete} />
                        )}
                        <Divider />
                        <SubTitle>Payment Methods</SubTitle>
                        <MultiSelect options={paymentMethodOptions} selected={paymentMethods} toggleCheckBox={this.onMultiSelectChange} propertyId='paymentMethods' />
                        <Divider />
                        <SubTitle>Extra info</SubTitle>
                        <SubContainer>
                            <FormItem className="fullColumn">
                                <Label htmlFor="logo">Logo</Label>
                                <TextField id='logo' value={logo} onChange={this.onChange} />
                            </FormItem>
                        </SubContainer>
                    </LeftColumn>
                    <RightColumn id="bottom">
                        <FormItem className="fullColumn">
                            <Label htmlFor="status">Listing status</Label>
                            <Select
                                id="status"
                                value={status}
                                onChange={({ target }) => this.onChange({ target: { id: 'status', value: target.value } })}
                                options={listingStatus.map(({ key, text }) => ({
                                    key: key,
                                    value: key,
                                    text: `${text}`,
                                }))}
                            />
                        </FormItem>
                        <Button className={sendStatus} onClick={this.save} disabled={(['processing', 'done'].includes(sendStatus))}>
                            {sendStatus === 'initial' && 'Save'}
                            {sendStatus === 'processing' && <div>Saving<span>.</span><span>.</span><span>.</span></div>}
                            {sendStatus === 'done' && 'Saved!'}
                        </Button>
                    </RightColumn>
                </Container>
            </ScreenContainer>

        )
    }
}