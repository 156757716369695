import React from 'react';
import { Route } from 'react-router-dom';
import HomeScreen from './home-screen';
import LoginScreen from './login-screen';
import AddOrUpdateBusinessItemScreen from './add-or-update-business-item-screen';
import ListingDetailScreen from './listing-detail-screen';


export const routes = () => [
    <Route key={'login-screen'} path='/login' exact render={ props => <LoginScreen {...props} /> } />,
    <Route key={'edit-business-item-screen'} path='/edit/:permalink' exact render={ props => <AddOrUpdateBusinessItemScreen {...props} /> } />,
    <Route key={'add-business-item-screen'} path='/add' exact render={ props => <AddOrUpdateBusinessItemScreen {...props} /> } />,
    <Route key={'home-screen-routes'} path='/*' exact render={ props => 
        <>
            <Route key={'home-screen'} path='/:path*' exact render={ props => <HomeScreen {...props} /> } {...props} />
            <Route key={'listing-detail-screen'} path='/listing/:permalink' exact render={ props => <ListingDetailScreen {...props} /> }  {...props} />
        </>
    } />,
];