import { useRef, useState, useContext, useCallback } from "react";
import { getAlgolia } from "./algolia";
import { UserContext } from "./contexts";

export const useSearch = (collectionName, collection=[]) => {
    const user = useContext(UserContext);
    const userRole = user?.claims?.role;
    const [filteredCollection, setFilteredCollection] = useState(null);
    const [searchedValue, setSearchedValue] = useState('');
    const [loading, setLoading] = useState('');
    const timeoutRef = useRef();
    const search = useCallback( (value) => {
        value = value.trim(); //Eliminate spaces only search queries.
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (value === searchedValue) {
            return; // Nothing changed;
        }

        if (value === '') {
            const firstPart = collection.slice(0, 25);
            //A trick to improve rendering performance.
            //NOTE: make sure to use the object's id as a key on components!
            setFilteredCollection(firstPart);
            setTimeout( () => {
                setFilteredCollection(null);
                setSearchedValue(value);
            }, 300);
        }
        else {
            timeoutRef.current = setTimeout( async () => {
                setLoading(true);
                
                const client = getAlgolia();
                const index = client.initIndex(collectionName);
                let {hits} = await index.search(value);
                hits.forEach( h => h.id = h.objectID );
                if (userRole !== 'admin') {
                    hits = hits.filter( h => h.status === 'published' );
                }
                setFilteredCollection(hits);
                setSearchedValue(value);
                setLoading(false);
            }, 100);
        }
    }, [collection, collectionName, searchedValue, userRole]);
    
    return [filteredCollection ?? collection, search, loading];
}

export const useBusinessSearch = (businessCollection) => useSearch('businesses', businessCollection);