import React from 'react';
import styled from 'styled-components';
import { borderColor, bodyColor, textColor } from '../../themes';

const Container = styled.footer`
    background-color:${bodyColor};
    width:100%;
    padding:15px;
    display: flex;
    text-align:center;
    justify-content:center;
    border-top: 1px solid ${borderColor};
`;

const Meta = styled.span`
    font-size:12px;
    line-height:24px;
    color:${textColor};
    text-transform:uppercase;
`;

const Link = styled.a`
    font-size:14px;
    line-height:28px;
    color:#2589bd;  
`;

const Footer = () => {
    return (
        <Container>
            <Meta>
                Engineered by <Link href="https://www.cr38te.com" target="_blank">CR38TE</Link>
            </Meta>
        </Container>
    );
}
export default Footer;
