import React from 'react';
import styled from 'styled-components';

const MultiSelectContainer = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
`;
const FormCheck = styled.div`
    width:185px;
    margin-right:10px;
    margin-left:0;

    @media(max-width:480px) {
        width:calc( calc( 100vw - 80px ) / 2 );
        :nth-child(2n+0) {
            margin-right:0;
        }
    }
`;
const CheckInput = styled.input`
    margin-left:0;
`;
const CheckLabel = styled.label`
    font-weight: 700;
    font-size:12px;
    line-height:24px;
    margin-left: 3px;
`;

const TypeCheck = ({ id, checked, label, toggleCheckBox, propertyId }) =>
    <FormCheck>
        <CheckInput type="checkbox" id={id} checked={checked} onChange={(e) => toggleCheckBox(propertyId, e)} />
        <CheckLabel htmlFor={id}>{label}</CheckLabel>
    </FormCheck>

const MultiSelect = ({ options, selected, ...props }) =>
    <MultiSelectContainer>
        {options.map(({ key, text }, i) => <TypeCheck key={`multi-select-item-${i}`} id={key} label={text} checked={selected.includes(key)} {...props} />)}
    </MultiSelectContainer>

export default MultiSelect;