import React from 'react';
import styled from 'styled-components';
import { applyBorderPadding } from '../../themes';

const Container = styled.div`
    background-color:#FFFFFF;
    width: 100%;
    ${applyBorderPadding}
`;

const Input = styled.input`
    width: 100%;
    height: 35px;
    border:1px solid #F0F0F0;
    background-color:#F5F5F5;
    padding-left:15px;
    font-size:16px;
`;

const SearchBar = styled( ({value, setValue, onChange, inputRef, children, ...props}) => {
    return (
        <Container {...props} >
            <Input 
                ref={inputRef}
                placeholder="Search restaurant, cuisine, etc..."
                value={value}
                onChange={ e => {
                    if(setValue) setValue(e.target.value);
                    if(onChange) onChange(e.target.value);
                }}
            />
            {children}
        </Container>
    );
})``;

export default SearchBar;