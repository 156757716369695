import React from 'react';
import styled from "styled-components";
import {CloseIcon} from '../icons';

export const CloseButton = styled( ({onClick, ...props}) => (
    <div onClick={ onClick } {...props} >
        <CloseIcon />
    </div>
))`
    cursor: pointer
`;

export const Image = styled.img`
    object-fit: contain;
    box-shadow: 0px 3px 6px #00000029;
`;


export const Title = styled.span`
    font: Bold 20px/21px Roboto;
    color: #262626;
`;

export const Subtitle = styled.span`
    font: normal 12px/21px Roboto;
    color: #262626;
`;