import React from 'react';
import styled from "styled-components";
import { CompactOptions } from "./components/compact-options";

export const BusinessCompactServiceOptions = styled(({ options, ...props }) => {
    //Recover know fields from cache.
    const hasDelivery = options?.some( ({type}) => type === 'delivery') || false;
    const hasPickup = options?.some( ({type}) => type === 'pick-up' || type === 'take-out') || false;
    const hasDriveThrough = options?.some( ({type}) => type === 'drive-through') || false;
    const showDriveThrought = (hasDriveThrough && !hasPickup) || false;
    const compactOptions = [ 
        {name: 'Delivery', isCheck: hasDelivery},
        showDriveThrought ? 
            {name: 'Drive Through', isCheck: hasDriveThrough}
            :
            {name: 'Take Out', isCheck: hasPickup}
    ];
    return <CompactOptions options={compactOptions} {...props} />
})``;