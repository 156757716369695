import React from 'react';
import styled from "styled-components";
import { PhoneIcon, WhatsappIcon, IconContainer } from "../../../components/icons";

const TYPE_ICONS = {'phone': PhoneIcon, 'whatsapp': WhatsappIcon};
const TYPE_LABELS = {'phone': 'Call', 'whatsapp': 'Message'};

const phoneRef = (number='') => `tel:${number.trim()}`;
const whatsappRef = (number='') => `https://wa.me/${number.trim().replace(/[+-]/g, '')}`;
const TYPE_LINK_REFS = {'phone': phoneRef, 'whatsapp': whatsappRef};

const ContactButton = styled(({number, type, ...props}) => {
    const Icon = TYPE_ICONS[type] || PhoneIcon;
    const label = TYPE_LABELS[type] || 'Call';
    const reference = TYPE_LINK_REFS[type] || phoneRef;
    return (
        <div {...props} onClick={ () => window.open(reference(number))}>
            <Icon /><span>{label}</span>
        </div>
    );
})`
    flex: 1;

    display: flex;
    justify-content: center; 
    align-items: center;
    height: 37px;
    cursor: pointer;
    
    background: #AAC962 0% 0% no-repeat padding-box;

    font: normal 12px/21px Roboto;
    color: #FFFFFF;

    ${IconContainer} {
        color: white;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        margin-bottom: 2px;
    }
`;

export const ContactOptions = styled( ({options=[], ...props}) => {
    const usedOptions = options.filter( o => (o.type === 'phone' || o.type === 'whatsapp') );
    return (
        <div {...props}>
            {usedOptions.map( option => 
                <ContactButton number={option.number} type={option.type}/>
            )}
        </div>
    );
})`
    flex-basis: 100%;
    
    display: flex;
    justify-content: stretch;
    align-items: center;

    ${ContactButton} + ${ContactButton} {
        margin-left: 10px;
    }
`;
