import React from 'react';
import styled from "styled-components";
import {EditIcon as BaseEditIcon} from '../icons';
import {MinusIcon as BaseMinusIcon} from '../icons';

const EditIcon = styled(BaseEditIcon)`
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 13px;
    opacity: 0.5;  
`;

export const EditButton = ({onClick}) => (
    <EditIcon onClick={ onClick } />
);

const MinusIcon = styled(BaseMinusIcon)`
    cursor: pointer;
    position: absolute;

    top: 6px;
    right: 45px;
    opacity: 0.5;
    svg {
        color:red;
    }
`;

export const DeleteButton = ({className, onClick}) => (
    <MinusIcon onClick={ onClick } className={className} />
);