import React from 'react';
import styled from 'styled-components';
import { TimesCirlceIcon } from '../../components/icons';
import AutoSugguestInputField from '../auto-suggest-input-field';
import Preloader from '../preloader';
import { useTagsData, useSaveTagsItemData } from '../../utils/data';
import { urlify } from '../../utils/format';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;

const PillsContainer = styled.div`
    width: 80%;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    background-color:${props => [props.bgColor]};
    padding:${props => [props.padding]};

    @media(max-width:650px) {
        flex-direction:column;
    }
`;

const AutoSuggestFieldContainer = styled.div`
    width: 20%;
    @media(max-width:650px) {
        width:100%;
        margin-right:0px;
    }
`;

const RemovePillButton = styled(TimesCirlceIcon)`
    position: absolute;
    right: 4px;
    top: 4px;
    min-width: 12px;
    width: 12px;
    max-width: 12px;
    height: 12px;
    min-height: 12px;
    max-height: 12px;
`;

const Pill = styled.div`
    max-height:29px;
    position: relative;
    background-color: #FFF;
    border: none;
    color: black;
    padding: 0px 20px 0px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0px 5px 10px 5px;
    cursor: pointer;
    border-radius: 20px;
    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
`;

const PillLabel = styled.p`
    margin: 4px 0px;
    padding: 0px;
`;

const AddTagToCollection = (id, label) => {
    useSaveTagsItemData(id, { label });
}

const TagsSection = ({ selected = [], propertyId, onChange }) => {
    const [tags, loading, /*error*/] = useTagsData();

    const onSuggestionSelected = ({ isNew = false, value = '', id, label, }) => {

        if (isNew) {
            id = urlify(value);
            label = value[0].toUpperCase() + value.substring(1);
            AddTagToCollection(id, label);
        }
        selected.push({ value: id, text: label });
        onChange({ target: { id: propertyId, value: selected } });
    }

    const onDeletePill = index => {
        selected.splice(index, 1);
        onChange({ target: { id: propertyId, value: selected } });
    }

    return (
        <Container>
            <PillsContainer>
                {selected.map(({ value, text }, i) =>
                    <Pill key={`pill-item-${value}-${i}`} onClick={() => onDeletePill(i)}>
                        <RemovePillButton />
                        <PillLabel>{text}</PillLabel>
                    </Pill>
                )}
            </PillsContainer>
            {loading ?
                <Preloader />
                :
                <AutoSuggestFieldContainer>
                    <AutoSugguestInputField items={tags} onSelected={onSuggestionSelected} />
                </AutoSuggestFieldContainer>
            }
        </Container>
    );
}
export default TagsSection;