import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { createGlobalStyle } from "styled-components";
import { useMediaQuery } from 'react-responsive'

export const GlobalStyle = createGlobalStyle`
	*,
	*::after,
	*::before {
		box-sizing:border-box;
	}
    body {
        margin:0;
		padding:0;
        font-family: 'Roboto', sans-serif;
        color:${({ theme }) => theme.text};
        background-color:${({ theme }) => theme.bodyColor};
    }
`;

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  return theme || {};
}

const baseTheme = {
    mobileBoundryWidth: 736,

    //The height of the header at different modes.
    headerHeight: 56,
    headerHeightMobile: 48,
    
    borderPadding: 30,
    borderPaddingMobile: 10,

    searchBarHeight: 100,
    searchBarHeightMobile: 55,

    //Default type colors.
    businessTypeColors: {
        'restaurant': {primaryColor: '#E25B45'}, 
        'cafe':  {primaryColor: '#E25B45'}, 
        'bar':  {primaryColor: '#89D5CB'}, 
        'snack-bar':  {primaryColor: '#E25B45'}, 
        'food-truck':  {primaryColor: '#E25B45'}, 
        'supermarket':  {primaryColor: '#FF8259'}, 
        'bakery-pastry':  {primaryColor: '#E25B45'}, 
        'wholesaler-distributor':  {primaryColor: '#FBC072'}, 
        'farm-local':  {primaryColor: '#AAC962'}, 
        'other':  {primaryColor: '#89D5CB'},
    }
}

export const bodyColor = ({theme}) => theme.bodyColor;
export const textColor = ({theme}) => theme.textColor;
export const lightTextColor = ({theme}) => theme.lightTextColor;
export const borderColor = ({theme}) => theme.borderColor;
export const primaryColor = ({theme}) => theme.primaryColor;
export const headerHeight = ({theme}) => theme.headerHeight;
export const headerHeightMobile = ({theme}) => theme.headerHeightMobile;
export const mobileBoundryWidth = ({theme}) => theme.mobileBoundryWidth;
export const borderPadding = ({theme}) => theme.borderPadding;
export const borderPaddingMobile = ({theme}) => theme.borderPaddingMobile;
export const businessTypePrimaryColor = (typePropertyName='type') => ({theme,...props}) => {
    return theme.businessTypeColors[props[typePropertyName] ?? 'other']?.primaryColor;
};

export const applyBorderPadding = ({theme}) => {
    return `
        padding: ${theme.borderPadding}px;

        @media (max-width: ${theme.mobileBoundryWidth}px) {
            padding: ${theme.borderPaddingMobile}px;
        }
    `;
}

//Hooks
export const useMobileBoundry = () => useMediaQuery({ query: `(max-width: ${baseTheme.mobileBoundryWidth}px)`});
export const useDarkModeQuery = () => useMediaQuery({ query: '(prefers-color-scheme: dark)'});


export const lightTheme = {
    ...baseTheme,
    bodyColor: '#ffffff',
    textColor: '#262626',
    lightTextColor: '#5F5F5F',
    borderColor: '#F0F0F0',
    primaryColor: '#89D5CB',
    logo: require('./logo_dark.svg')
}

export const darkTheme = {
    ...baseTheme,
    bodyColor: '#262626',
    textColor: '#ffffff',
    borderColor: 'rgba(255,255,225,0.05)',
    primaryColor: '#89D5CB',
    logo: require('./logo_light.svg')
}

export const typeOfColor = {
    'wholesalerDistributor': '#89D5CB',
    'restaurant': '#E25B45',
    'supermarket': '#AAC962',
    'other':'#262626'
}