import React from 'react';
import styled from 'styled-components';
import { TextField, CheckBoxField, ValueSetValidator } from '../../components/input';
import { DeleteButton } from '../../components/component-buttons';

const SubContainer = styled.div`
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    background-color:${props => [props.bgColor]};
    padding:${props => [props.padding]};
    border-bottom:1px solid white;

    @media(max-width:650px) {
        flex-direction:column;
    }

`;

const LeftColumn = styled.div`
    flex: 85%;
    display:flex;
    flex-wrap:nowrap;

    @media(max-width:1420px) {
        flex-direction:column;
        flex: 75%;
    }
`;

const RightColumn = styled.div`
    flex: 15%;
    align-items: center;
    height: 100%; 

    @media(max-width:1420px) {
        flex: 25%;
    }
`;

const FormItem = styled.div`
    margin-bottom:15px;
    width:calc( (100% - 30px ) / 2 );
    margin-right:15px;

    @media(max-width:1420px) {
        width:95%;
        margin-right:15px;
    }

    @media(max-width:650px) {
        width:100%;
        margin-right:0px;
    }

    .Error {
        border:1px solid #990000;
        background-color:rgba(153,0,0,0.3);
    }
    span.Error {
        display:none;
        visibility:hidden;
    }

    input, textarea {
        width:100%;
        height:35px;
        padding-left:10px;
        font-size:12px;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:0px;
        box-shadow:none;
        -webkit-appearance: none;
    }
    select {
        width:100%;
        height:35px;
        font-size:12px;
    }
    textarea {
        height:200px;
        padding:10px;
        border:1px solid rgba(0,0,0,0.1);
        
    }
    :last-child {
    }
`;

const Label = styled.label`
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    margin-left: 3px;
`;

export default class LocationItem extends React.Component {

    onPropertyChange = ({ target }) => {
        let { propertyId, index, onArrayItemChange, deleteArrayItem, ...props } = this.props;
        props[target.id] = target.value;
        onArrayItemChange(propertyId, props, index);
    }

    onDeleteItem = () => this.props.deleteArrayItem(this.props.propertyId, this.props.index)

    render() {
        const { address, name, latitude, logitude, isDefault } = this.props;
        return (
            <SubContainer bgColor="#f1f1f1" padding="15px">
                <LeftColumn>
                    <FormItem>
                        <Label htmlFor={`address`}>Address *</Label>
                        <TextField
                            id={`address`}
                            value={address}
                            onChange={this.onPropertyChange}
                            placeholder=''
                            validator={new ValueSetValidator(this.validator)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label htmlFor={`name`}>Name *</Label>
                        <TextField
                            id={`name`}
                            value={name}
                            onChange={this.onPropertyChange}
                            placeholder=''
                            validator={new ValueSetValidator(this.validator)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label htmlFor={`latitude`}>Latitude *</Label>
                        <TextField
                            id={`latitude`}
                            value={latitude}
                            onChange={this.onPropertyChange}
                            placeholder=''
                            validator={new ValueSetValidator(this.validator)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label htmlFor={`logitude`}>Longitude *</Label>
                        <TextField
                            id={`logitude`}
                            value={logitude}
                            onChange={this.onPropertyChange}
                            placeholder=''
                            validator={new ValueSetValidator(this.validator)}
                        />
                    </FormItem>
                </LeftColumn>
                <RightColumn>
                    <Label htmlFor={`isDefault`}>Default location</Label>
                    <CheckBoxField
                        id={`isDefault`}
                        value={isDefault}
                        onChange={({ target }) => this.onPropertyChange({ target: { id: 'isDefault', value: target.checked } })}
                        validator={new ValueSetValidator(this.validator)}
                    />
                </RightColumn>
                <DeleteButton onClick={this.onDeleteItem} />
            </SubContainer>
        )
    }
}