import React from 'react';
import styled from "styled-components";
import { CompactOptions } from './components';

export const BusinessCompactPaymentMethods = styled(({ methods, ...props }) => {
    const canUseCash = methods?.some( method => method === 'cash');
    const canUseDebit = methods?.some( method => method === 'debit-card');
    const options = [ 
        {name: 'Cash', isCheck: canUseCash},
        {name: 'Swipe', isCheck: canUseDebit},
    ];
    return <CompactOptions options={options} {...props} />
})``;