import styled from "styled-components";

const Button = styled.span`
    position:absolute;
    cursor: pointer;
    bottom:0px;
    width:100%;
    text-align:center;
    font-size:16px;
    line-height:24px;
    text-transform:uppercase;
    color:#ffffff;
    left:0;
    padding:10px 15px;
    transition:all 0.2s linear;
    text-decoration:none;
    background-color:#262626;

    &:hover {
        background-color:#89D5CB;
    }
`;

export default Button;