import React, { useContext } from 'react';
import styled from "styled-components";
import { Field } from './field';
import { PhoneIcon, DownArrowIcon } from '../icons';
import { Description, ContactInfoMenu } from './components';
import { ShowPopUp } from '../show-pop-up';
import { FieldCacheContext } from '.';
import { contactOptions } from '../../utils/constants';
import { getDescriptionOfList } from '../../utils/format';

export const formatContactInfoDescription = (options = []) => {
    let types = options.map(option => option.type);
    types = [...new Set(types)];
    types = types.sort(type => {
        if (type === 'phone') return -2;
        if (type === 'mobile') return -1;
        if (type === 'whatsapp') return 1;
        return 0;
    });

    types = types.map(type => {
        let text = 'Other';
        contactOptions.forEach(option => {
            if (option.key === type) {
                text = option.text;
            }
        });
        return text;
    });

    if (types.length > 0) {
        types[0] = types[0].slice(0, 1).toUpperCase() + types[0].slice(1);
    }
    return getDescriptionOfList(types, 'and');
}

export const BusinessContactInfo = styled(({ className, children, contactInfo}) => {
    //Recover know fields from cache.
    const cache = useContext(FieldCacheContext) || {};
    if (!cache.formatContactInfoDescription) {
        cache.formatContactInfoDescription = formatContactInfoDescription(contactInfo);
    }

    const hasContactInfo = (contactInfo.length !== 0);
    return (
        <ShowPopUp
            PopUpComponent={ () => <ContactInfoMenu contactInfo={contactInfo} /> }
            className={className}
        >
            <Field>
                <PhoneIcon />
                <Description>
                    {cache.formatContactInfoDescription}
                </Description>
                {hasContactInfo && <DownArrowIcon />}
                {children}
            </Field>
        </ShowPopUp>
    )
})``;