import React from 'react';
import styled from 'styled-components';
import {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon
} from '../../icons';

const Navigation = styled.nav`
    @media (max-width:1024px) {
    }

    @media (max-width:480px) {
    }
`;

const NavItem = styled.div`
    padding-left:15px;
    padding-right:15px;
    justify-self:center;
`;

const SocialList = styled.ul`
    list-style:none;
    margin:0;
    padding:0;
    display:flex;
    justify-content:center;
`;

const SocialItem = styled.li`
    text-align:left;
    padding-left:10px;
    padding-right:10px;
`;

const SocialLink = styled.a`
    cursor: pointer;
    margin-right:10px;

    &:hover {
        svg {
            color:#2589bd;
        }
    }
`;

export const SocialMediaLinks = () => {
    return (
        <Navigation>
            <NavItem>
                <SocialList>
                    <SocialItem>
                        <SocialLink href="https://www.facebook.com/MiKierCome/" title={'MiKierCome Facebook Page'} target="_blank">
                            <FacebookIcon />
                        </SocialLink>
                    </SocialItem>
                    <SocialItem>
                        <SocialLink href="#" title={'title here'} target="_blank">
                            <TwitterIcon />
                        </SocialLink>
                    </SocialItem>
                    <SocialItem>
                        <SocialLink href="#" title={'title here'} target="_blank">
                            <InstagramIcon />
                        </SocialLink>
                    </SocialItem>
                </SocialList>
            </NavItem>
        </Navigation>
    );
}
