import {
    FacebookIcon, 
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    SnapchatIcon,
    PinterestIcon,
    YoutubeIcon,
    Tripadvisor,
    PhoneIcon,
    MobileIcon,
    WhatsappIcon,
    FaxIcon
}
from '../components/icons';

export const Icons = {
    'facebook': FacebookIcon,
    'instagram': InstagramIcon,
    'twitter': TwitterIcon,
    'snapchat': SnapchatIcon,
    'pinterestIcon': PinterestIcon,
    'youtubeIcon': YoutubeIcon,
    'linkedin': LinkedinIcon,
    'tripadvisor': Tripadvisor,
    'phone': PhoneIcon,
    'mobile': MobileIcon,
    'whatsapp': WhatsappIcon,
    'fax': FaxIcon,
}
export default Icons;