import React from 'react';
import styled, { useTheme } from 'styled-components';
import { 
    mobileBoundryWidth, businessTypePrimaryColor
} from '../../../themes';
import { IconContainer, DownArrowIcon } from '../../icons';
import { 
    BusinessStatus, 
    BusinessLogo, 
    BusinessName, 
    BusinessOperatingHours, 
    BusinessCompactServiceOptions, 
    BusinessCompactPaymentMethods, 
    ViewMoreButton 
} from '.';
import { FieldCacheContext } from '../../business-item-fields';

//Contstants
const MOBILE_CELL_HEIGHT = 80;
const DESKTOP_IMAGE_HEIGHT = 120;
const DESKTOP_THIN_BORDER = 5;

const mobileStyling = ({theme, type}) => `
    position: relative;
    cursor: pointer;

    box-shadow: 0px 3px 6px #00000029;

    height: ${MOBILE_CELL_HEIGHT + 6}px;
    width: 100%;

    border: 1px ${theme.borderColor} solid;
    border-radius: 3px;
    border-bottom: 5px ${businessTypePrimaryColor()({theme, type})} solid;

    margin: ${ theme.borderPaddingMobile / 2}px;
    margin-top: 0px;
    
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    user-select: none;

    padding-left: ${MOBILE_CELL_HEIGHT + theme.borderPaddingMobile}px;
    padding-bottom: 5px;

    ${BusinessStatus} {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    ${BusinessLogo} {
        position: absolute;
        top: 0px;
        left: 0px;
        height: ${MOBILE_CELL_HEIGHT}px;
        width: ${MOBILE_CELL_HEIGHT}px;
    }

    ${BusinessName} {
        margin-bottom: -1px;
        width: 100%;
    }

    ${BusinessOperatingHours} {
        pointer-events: none;
        margin-bottom: -2px;
        width: 100%;

        ${IconContainer} {
            width: 10px;
            height: 10px;
        }

        ${DownArrowIcon} {
            display: none;
        }

        span {
            font-size: 10px;
        }
    }

    ${BusinessCompactServiceOptions} {
        margin-bottom: 0px; 
    }

    ${BusinessCompactPaymentMethods} {
        margin-bottom: 0px;
        
        ${IconContainer} {
            opacity: 0.7;
        }
    }
`;

const desktopStyling = ({theme, type}) => `
    position: relative;

    width: 290px;
    height: 400px;

    box-shadow: 0px 3px 6px #00000029;
    border: 1px ${theme.borderColor} solid;
    border-radius: 3px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    padding-top: ${DESKTOP_THIN_BORDER + DESKTOP_IMAGE_HEIGHT + (theme.borderPadding/2)}px;
    padding-left: ${theme.borderPadding}px;
    padding-bottom: ${44 /*temp height of button*/ + (theme.borderPadding/2)}px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    ${BusinessStatus} {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    ${BusinessLogo} {
        position: absolute;
        top: ${DESKTOP_THIN_BORDER}px;
        left: calc(50% - ${DESKTOP_IMAGE_HEIGHT/2}px);
        height: ${DESKTOP_IMAGE_HEIGHT}px;
        width: ${DESKTOP_IMAGE_HEIGHT}px;
    }

    ${BusinessName} {
        margin-bottom: ${theme.borderPadding/2}px;
        padding-bottom: ${theme.borderPadding/2}px;
        border-bottom: 1px ${theme.borderColor} solid;
        margin-left: -${theme.borderPadding}px;
    }

    ${ViewMoreButton} {
        background: ${businessTypePrimaryColor()({theme, type})};
    }
`;

const ContainerContents = ({children=[], onClick, cache, ...props}) => {
    const theme = useTheme();
    const wrappedOnClick = (...e) => {
        if (window.innerWidth < theme.mobileBoundryWidth) {
            onClick && onClick(...e);
        }
    }
    return (
        <FieldCacheContext.Provider value={cache}>
            <div {...props} onClick={wrappedOnClick}> {children} </div>
        </FieldCacheContext.Provider>
    );
}

export const Container = styled(ContainerContents)` 
    
    @media (max-width: ${mobileBoundryWidth}px) {
        ${mobileStyling}
    }

    @media (min-width: ${mobileBoundryWidth}px) {
        ${desktopStyling}
    }
`;

