import React from 'react';
import styled from "styled-components";
import { ContentBlockComponent } from "../../../components/content-block/content-block";
import { mobileBoundryWidth } from '../../../themes';

const BusinessInformationContainer = styled.div`
    padding-top: 5px;
    background-color: white;
    padding: 0px 15px;
`;

const BusinessInformationTitle = styled.h2`
    font-size: 26px;
    text-align: center;

    @media(max-width: ${mobileBoundryWidth}px) {
        text-align: left;
    }
`;

export const Content = styled( ({ aboutContent, orderContent, deliveryContent, ...props}) => {
    const [aboutTitle='About', aboutDescription='No about information available.'] = aboutContent;
    const [orderTitle='Order', orderDescription='No order information available.'] = orderContent;
    const [deliveryTitle='Delivery', deliveryDescription='No delivery information available.'] = deliveryContent;
    return (
        <BusinessInformationContainer {...props} >
            <BusinessInformationTitle>{aboutTitle}</BusinessInformationTitle>
            <ContentBlockComponent description={aboutDescription} />
            <ContentBlockComponent title={orderTitle} description={orderDescription} />
            <ContentBlockComponent title={deliveryTitle} description={deliveryDescription} />
        </BusinessInformationContainer>
    )
})``;