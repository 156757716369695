import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container } from './component-containers';
import { 
    LogoButton, 
    SearchButton, 
    FilterButton,
    SearchBar
} from './component-fields';
import { useHistory } from 'react-router';
import SearchResultsScreen from '../../screens/search-results-screen';

class Header {
    reference = -1;
    state = {
        searchValue:'', 
        searchEnabled:false
    }
    __registeredSetStateCallbacks = new Map();

    onHeaderStateChanged = (callback) => {
        const _callbackRefernce = this.reference;
        this.__registeredSetStateCallbacks.set(_callbackRefernce, callback);
        const unsubscribe = () => {

            this.__registeredSetStateCallbacks.delete(_callbackRefernce);
        }
        this.reference = this.reference + 1;
        return unsubscribe;
    }

    dismissSearchBar = () => {
        this.__setState({searchEnabled: false, searchValue: ''});
    }
    
    __setState = (state={}) => {
        this.state = {...this.state, ...state};
        this.__registeredSetStateCallbacks.forEach( setState =>
            setState(this.state)
        );
    }
}

const __header = new Header();
export const useHeader = () => {
    return __header;
}

const HeaderComponent = ({onSearchEnabled}) => {
    const searchBarInputRef = useRef(null);
    const history = useHistory();
    const [searchEnabled, setSearchEnabled] = useState(__header.state.searchEnabled);
    const [searchValue, setSearchValue] = useState(__header.state.searchValue);
    const onStateChange = useCallback( ({searchValue: value, searchEnabled: enabled}) => {
        if (enabled !== searchEnabled) {
            setSearchEnabled(enabled);
            onSearchEnabled(enabled);
        }
        if (value !== searchValue) {
            setSearchValue(value);
        }
    }, [onSearchEnabled, searchEnabled, searchValue]);

    useEffect( () => {
        return __header.onHeaderStateChanged( onStateChange );
    }, [onStateChange, searchEnabled, searchValue]);

    return (
        <Container searchEnabled={searchEnabled} >
            <LogoButton 
                onClick={ () => history.push('/') }
            />
            <SearchButton 
                onClick={ () => {
                    const enable = !searchEnabled;
                    searchBarInputRef.current.value = '';
                    __header.__setState({searchEnabled: enable, searchValue: ''});
                    
                    if (enable)
                        searchBarInputRef.current.focus();
                    else 
                        searchBarInputRef.current.blur();
                }} 
            />
            {false && /*We will hide the filter button untill feature is enabled */
                <FilterButton 
                    onClick={ () => history.push('/')} 
                />
            }
            <SearchBar 
                inputRef={ searchBarInputRef }
                onChange={ searchValue => __header.__setState({searchValue})} 
                onClose={ () => {
                    const currentValue = __header.state.searchValue;
                    const alsoDisable = (currentValue.length === 0);
                    __header.__setState({
                        searchEnabled: !alsoDisable,
                        searchValue: ''
                    });
                    searchBarInputRef.current.value = '';
                }}
            />  
            {(searchEnabled && searchValue.trim().length > 0) && 
                <SearchResultsScreen 
                    onClickItem={ () => __header.__setState({
                        searchEnabled: false,
                        searchValue: '',
                    })}
                />
            }
        </Container>
    );
}
export default HeaderComponent;