import React from 'react';
import styled from "styled-components";
import { Popup, List, Item } from './popup-with-items';
import { weekdayOptions } from '../../../utils/constants';

const DateItem = styled.span`
    margin-right:10px;
    flex-basis:70px;

    &.active {
        font-weight:bold;
    }
`;

const HoursItem = styled.span`
    text-align:right;
    flex-basis:100%;
`;

export const HoursMenu = ({ hours=[] }) => {
    if (!hours.length) return null;
    return (
        <Popup>
            <List>
                {hours.map(({ weekday, from, to }, i) =>
                    <Item key={`hours-item-${i}`}>
                        <DateItem className="active">{(weekdayOptions.find(({ key }) => key === weekday) || {}).text}:</DateItem>
                        <HoursItem>{`${from} - ${to}`}</HoursItem>
                    </Item>
                )}
            </List>
        </Popup>
    )
}