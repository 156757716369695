import styled from "styled-components";
import { IconContainer } from "../icons";

export const Field = styled.div`
    text-align:left;
    margin-bottom:5px;
    color:#262626;
    font-size:13px;
    line-height:24px;
    font-weight:400;
    display:flex;
    align-items:center;
    position:relative;
    flex-basis:auto;

    ${IconContainer} {
        max-width: 15px;
        height: 20px;
        color: #262626;
        margin-right: 5px;
    }
`;