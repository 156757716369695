export const businessTypeOptions = [
    { key: 'restaurant', text: 'Restaurant' },
    { key: 'cafe', text: 'Cafe' },
    { key: 'bar', text: 'Bar' },
    { key: 'snack-bar', text: 'Snack Bar' },
    { key: 'food-truck', text: 'Food Truck' },
    { key: 'supermarket', text: 'Supermarket' },
    { key: 'bakery-pastry', text: 'Bakery /Pastry' },
    { key: 'wholesaler-distributor', text: 'Wholesaler / Distributor' },
    { key: 'farm-local', text: 'Farm / Local' },
    { key: 'other', text: 'Other' },
];

export const paymentMethodOptions = [
    { key: 'cash', text: 'Cash' },
    { key: 'debit-card', text: 'Debit card' },
    { key: 'credit-card', text: 'Credit card' },
    { key: 'bank-transfer', text: 'Bank transfer' },
    { key: 'payaw', text: 'PayAW' },
    { key: 'gift-voucher', text: 'Gift voucher' },
];

export const cuisineOptions = [
    { key: 'aruban-authentic', text: 'Aruba / Authentic' },
    { key: 'american', text: 'American' },
    { key: 'arab', text: 'Arab' },
    { key: 'argentinian', text: 'Argentinian' },
    { key: 'belgian', text: 'Belgian' },
    { key: 'brazilian', text: 'Brazilian' },
    { key: 'buffet', text: 'Buffet' },
    { key: 'cafe', text: 'Cafe' },
    { key: 'cajun', text: 'Cajun' },
    { key: 'chilean', text: 'Chilean' },
    { key: 'chinese', text: 'Chinese' },
    { key: 'colombian', text: 'Colombian' },
    { key: 'cuban', text: 'Cuban' },
    { key: 'dutch', text: 'Dutch' },
    { key: 'farm', text: 'Farm' },
    { key: 'fast-food', text: 'Fast food' },
    { key: 'french', text: 'French' },
    { key: 'german', text: 'German' },
    { key: 'greek', text: 'Greek' },
    { key: 'grill-bbq', text: 'Grilled / BBQ' },
    { key: 'healthy', text: 'Healthy' },
    { key: 'indian', text: 'Indian' },
    { key: 'indonesian', text: 'Indonesian' },
    { key: 'italian', text: 'Italian' },
    { key: 'jamaican', text: 'Jamaican' },
    { key: 'japanese', text: 'Japanese' },
    { key: 'javanese', text: 'Javanese' },
    { key: 'korean', text: 'Korean' },
    { key: 'lebanese', text: 'Lebanese' },
    { key: 'liquor', text: 'Liquor' },
    { key: 'mediterranean', text: 'Mediterranean' },
    { key: 'mexican', text: 'Mexican' },
    { key: 'moroccan', text: 'Moroccan' },
    { key: 'peruvian', text: 'Peruvian' },
    { key: 'pizza', text: 'Pizza' },
    { key: 'portuguese', text: 'Portuguese' },
    { key: 'seafood', text: 'Seafood' },
    { key: 'soul-food', text: 'Soul Food' },
    { key: 'spanish', text: 'Spanish' },
    { key: 'supermarket', text: 'Supermarket' },
    { key: 'surinamese', text: 'Surinamese' },
    { key: 'sushi', text: 'Sushi' },
    { key: 'thai', text: 'Thai' },
    { key: 'turkish', text: 'Turkish' },
    { key: 'vegan', text: 'Vegan' },
    { key: 'vegetarian', text: 'Vegetarian' },
    { key: 'venezuelan', text: 'Venezuelan' },
    { key: 'vietnamese', text: 'Vietnamese' },
    { key: 'wine', text: 'Wine' },
];

export const contactOptions = [
    { key: 'phone', text: 'Phone' },
    { key: 'mobile', text: 'Mobile' },
    { key: 'whatsapp', text: 'Whatsapp' },
    { key: 'fax', text: 'Fax' },
];

export const serviceOptions = [
    { key: 'delivery', text: 'Delivery' },
    { key: 'pick-up', text: 'Pick-up' },
    { key: 'drive-through', text: 'Drive-through' },
    { key: 'take-out', text: 'Take-out' },
    { key: 'dine-in', text: 'Dine-in' },
];

export const socialMediaOptions = [
    { key: 'facebook', text: 'facebook' },
    { key: 'instagram', text: 'instagram' },
    { key: 'twitter', text: 'twitter' },
    { key: 'snapchat', text: 'snapchat' },
    { key: 'pinterest', text: 'pinterest' },
    { key: 'youtube', text: 'youtube' },
    { key: 'linkedin', text: 'linkedin' },
];

export const weekdayOptions = [
    { key: 'mon', text: 'Monday' },
    { key: 'tue', text: 'Tuesday' },
    { key: 'wed', text: 'Wednesday' },
    { key: 'thu', text: 'Thursday' },
    { key: 'fri', text: 'Friday' },
    { key: 'sat', text: 'Saturday' },
    { key: 'sun', text: 'Sunday' },
];

export const timeslotOptions = [
    { key: '0:00', text: '0:00' },
    { key: '0:15', text: '0:15' },
    { key: '0:30', text: '0:30' },
    { key: '0:45', text: '0:45' },
    { key: '1:00', text: '1:00' },
    { key: '1:15', text: '1:15' },
    { key: '1:30', text: '1:30' },
    { key: '1:45', text: '1:45' },
    { key: '2:00', text: '2:00' },
    { key: '2:15', text: '2:15' },
    { key: '2:30', text: '2:30' },
    { key: '2:45', text: '2:45' },
    { key: '3:00', text: '3:00' },
    { key: '3:15', text: '3:15' },
    { key: '3:30', text: '3:30' },
    { key: '3:45', text: '3:45' },
    { key: '4:00', text: '4:00' },
    { key: '4:15', text: '4:15' },
    { key: '4:30', text: '4:30' },
    { key: '4:45', text: '4:45' },
    { key: '5:00', text: '5:00' },
    { key: '5:15', text: '5:15' },
    { key: '5:30', text: '5:30' },
    { key: '5:45', text: '5:45' },
    { key: '6:00', text: '6:00' },
    { key: '6:15', text: '6:15' },
    { key: '6:30', text: '6:30' },
    { key: '6:45', text: '6:45' },
    { key: '7:00', text: '7:00' },
    { key: '7:15', text: '7:15' },
    { key: '7:30', text: '7:30' },
    { key: '7:45', text: '7:45' },
    { key: '8:00', text: '8:00' },
    { key: '8:15', text: '8:15' },
    { key: '8:30', text: '8:30' },
    { key: '8:45', text: '8:45' },
    { key: '9:00', text: '9:00' },
    { key: '9:15', text: '9:15' },
    { key: '9:30', text: '9:30' },
    { key: '9:45', text: '9:45' },
    { key: '10:00', text: '10:00' },
    { key: '10:15', text: '10:15' },
    { key: '10:30', text: '10:30' },
    { key: '10:45', text: '10:45' },
    { key: '11:00', text: '11:00' },
    { key: '11:15', text: '11:15' },
    { key: '11:30', text: '11:30' },
    { key: '11:45', text: '11:45' },
    { key: '12:00', text: '12:00' },
    { key: '12:15', text: '12:15' },
    { key: '12:30', text: '12:30' },
    { key: '12:45', text: '12:45' },
    { key: '13:00', text: '13:00' },
    { key: '13:15', text: '13:15' },
    { key: '13:30', text: '13:30' },
    { key: '13:45', text: '13:45' },
    { key: '14:00', text: '14:00' },
    { key: '14:15', text: '14:15' },
    { key: '14:30', text: '14:30' },
    { key: '14:45', text: '14:45' },
    { key: '15:00', text: '15:00' },
    { key: '15:15', text: '15:15' },
    { key: '15:30', text: '15:30' },
    { key: '15:45', text: '15:45' },
    { key: '16:00', text: '16:00' },
    { key: '16:15', text: '16:15' },
    { key: '16:30', text: '16:30' },
    { key: '16:45', text: '16:45' },
    { key: '17:00', text: '17:00' },
    { key: '17:15', text: '17:15' },
    { key: '17:30', text: '17:30' },
    { key: '17:45', text: '17:45' },
    { key: '18:00', text: '18:00' },
    { key: '18:15', text: '18:15' },
    { key: '18:30', text: '18:30' },
    { key: '18:45', text: '18:45' },
    { key: '19:00', text: '19:00' },
    { key: '19:15', text: '19:15' },
    { key: '19:30', text: '19:30' },
    { key: '19:45', text: '19:45' },
    { key: '20:00', text: '20:00' },
    { key: '20:15', text: '20:15' },
    { key: '20:30', text: '20:30' },
    { key: '20:45', text: '20:45' },
    { key: '21:00', text: '21:00' },
    { key: '21:15', text: '21:15' },
    { key: '21:30', text: '21:30' },
    { key: '21:45', text: '21:45' },
    { key: '22:00', text: '22:00' },
    { key: '22:15', text: '22:15' },
    { key: '22:30', text: '22:30' },
    { key: '22:45', text: '22:45' },
    { key: '23:00', text: '23:00' },
    { key: '23:15', text: '23:15' },
    { key: '23:30', text: '23:30' },
    { key: '23:45', text: '23:45' },
    { key: '23:59', text: '23:59' },
];

export const listingStatus = [
    { key: 'published', text: 'Published'},
    { key: 'draft', text: 'Draft'},
    { key: 'trash', text: 'Trash'},
    // { key: 'protected', text: 'Protected'},
]

export const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];