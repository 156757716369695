import React from 'react';
import CardContainer from '../card-container';
import { PlusIcon } from '../icons';
import styled from 'styled-components';

export const AddItem = () => {
    return (
        <CardContainer border={'dashed'} to={'/add'}>
            <PlusIcon />
        </CardContainer>
    );
}

export const EmptyListItem = styled( (props) => {
    return (
        <span {...props}>No businesses found.</span>
    );
})` 
    margin-top: 70px;
    margin-bottom: 100px;
`;
