import React, { useContext } from 'react';
import styled from "styled-components";
import { getDescriptionOfList } from '../../utils/format';
import { CardIcon } from "../icons";
import { Field } from "./field";
import { FieldCacheContext } from '.';
import { paymentMethodOptions } from '../../utils/constants';

const formatPaymentMethodDescription = (types = []) => {
    const methods = { 'cash': 0, 'debit-card': 1, 'credit-card': 2 };
    types = types.sort((type1, type2) => (methods[type1] || 0) - (methods[type2] || 0));

    types = types.map(type => {
        let text = 'Other';
        paymentMethodOptions.forEach(option => {
            if (option.key === type) {
                text = option.text;
            }
        });
        return text;
    });
    return getDescriptionOfList(types);
}

export const BusinessPaymentMethodsDescription = styled(({ className, children, methods }) => {
    //Recover know fields from cache.
    const cache = useContext(FieldCacheContext) || {};
    if (!cache.formatPaymentMethodDescription) {
        cache.formatPaymentMethodDescription = formatPaymentMethodDescription(methods);
    }
    
    return (
        <Field className={className}>
            <CardIcon />
            {cache.formatPaymentMethodDescription}
            {children}
        </Field>
    );
})``;
