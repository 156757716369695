import { useEffect, useState, useRef } from "react";

var _firebase = null;
export const getFirebase = () => {
    if (!_firebase) {
        _firebase = require("firebase/app");
        _firebase.initializeApp({
            "projectId": "mikiercome-961bb",
            "appId": "1:735863162154:web:ada4ad5bd46af185228a53",
            "databaseURL": "https://mikiercome-961bb.firebaseio.com",
            "storageBucket": "mikiercome-961bb.appspot.com",
            "apiKey": "AIzaSyDLqZ3fExx-_FeCjwNd8MeaMQD9zPnzH0Q",
            "authDomain": "mikiercome-961bb.firebaseapp.com",
            "messagingSenderId": "735863162154",
            "measurementId": "G-PTZZF66HE9"
        });
    }
    return _firebase;
}

//AUTH HOOKs
export const useUser = () => {
    const firebase = getFirebase();
    require('firebase/auth');

    const [isSetup, setIsSetup] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        let unsubscribe = () => null;

        const load = async () => {
            setLoading(true);
            unsubscribe = firebase.auth().onIdTokenChanged(
                async user => {
                    if (user) {
                        const idTokenResult = await user.getIdTokenResult(true);
                        user.claims = idTokenResult.claims;
                    }
                    setUser(user);
                    setLoading(false);
                },
                error => {
                    console.error(error);
                    setError(error);
                    setLoading(false);
                }
            );
        };
        if (!isSetup) {
            load();
            setIsSetup(true);
        }
        return () => {
            if (isSetup) {
                unsubscribe();
            }
        }
    }, [isSetup, firebase]);

    return [user, loading, error];
}

//UNIQUE-IDs
export const generateUniqueJobID = collectionName => {
    require('firebase/firestore');
    return getFirebase().firestore().collection(collectionName).doc().id;
}
export const generateUniqueBusinessesID = () => generateUniqueJobID('businesses');

//USE DATA HOOKS
export const useData = (collectionName, optionalQuery) => {
    const firebase = getFirebase();
    require('firebase/firestore');

    const [setupQuery, setSetupQuery] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const timeoutRef = useRef();

    useEffect(() => {
        let unsubscribe = () => null;

        const load = async () => {
            setLoading(true);
            let ref = firebase.firestore().collection(collectionName);
            ref = optionalQuery ? optionalQuery(ref) : ref;
            unsubscribe = ref.onSnapshot(
                (querySnap) => {
                    
                    const allData = querySnap.docs.map(
                        doc => ({ id: doc.id, ...doc.data() })
                    );
                    //We will set the data in pieces to prevent a render delay.
                    //NOTE: Must use the object id as a key for rendered items!
                    const firstPart = allData.slice(0, 25);
                    setData(firstPart);
                    setLoading(false);
                    setError(null);

                    //After render set the all of the data.
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => {
                        setData(allData);
                    }, 300);
                },
                (error) => {
                    console.error(error);
                    setError(error);
                    setLoading(false);
                }
            );
        };
        const isSetup = (setupQuery && setupQuery.query === optionalQuery);
        if (!isSetup) {
            load();
            setSetupQuery({query: optionalQuery});
        }
        return () => {
            if (isSetup) {
                unsubscribe();
            }
        }
    }, [setupQuery, collectionName, optionalQuery, firebase]);

    return [data, loading, error];
};

export const useBusinessesData = (optionalQuery) => useData('businesses', optionalQuery);
export const useTagsData = () => useData('tags');


const useItemData = (collectionName, permalink, defaultData = {}) => {
    require('firebase/firestore');

    const [setupLink, setSetupLink] = useState(false);
    const [loading, setLoading] = useState(!Boolean(defaultData));
    const [error, setError] = useState(null);
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        const load = async () => {
            getFirebase().firestore().collection(collectionName).where('permalink', '==', permalink).limit(1).get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            //FIX: Apply default data to maintain any cached information.
                            const newData = { ...defaultData, id: doc.id, ...doc.data() };
                            setData(newData);
                        }
                        setLoading(false);
                        setError(null);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    setError(error);
                });
        };
        if (permalink && permalink.length && setupLink !== permalink) {
            load();
            setSetupLink(permalink);
        }
    }, [setupLink, collectionName, permalink, defaultData]);

    return [data, loading, error];
}
export const useBusinessItemData = (permalink, defaultData) => useItemData('businesses', permalink, defaultData);

const useSaveItemData = (collectionName, id, itemData) => {
    require('firebase/firestore');
    getFirebase().firestore().collection(collectionName).doc(id).set(itemData)
        .then(function () {
            //alert('item data' + JSON.stringify(itemData));
            console.log("Document successfully written!");
        })
        .catch(function (error) {
            alert('Error writing to database item data' + JSON.stringify(itemData) + ' | error ' + error);
            console.error("Error writing to db", error);
            return error;
        });
}
export const useSaveBusinessItemData = (id, businessItemData) => useSaveItemData('businesses', id, businessItemData);
export const useSaveTagsItemData = (id, tagsItemData) => useSaveItemData('tags', id, tagsItemData);


//DELETE ITEMS
const deleteObject = (collectionName, objectID) => {
    const firebase = getFirebase();
    require('firebase/firestore');
    return firebase.firestore().collection(collectionName).doc(objectID).delete();
}
export const deleteBusiness = (businessID) => deleteObject('businesses', businessID);