import React from 'react';
import styled from "styled-components";
import { Field } from "./field";
import Icons from "../../utils/social-contact-icons";

const Link = styled.a`
    margin-right:8px;

    svg {
        color:#AAC962;
        width:18px;
    }
`;

export const BusinessSocialMedia = styled(({ className, children, socialMedia=[] }) =>
    <Field className={className}>
        {socialMedia.map(({ link, title, type }, i) => {
            const Icon = Icons[type];
            return (
                <Link key={`social-media-item-${i}`} href={link} target="_blank" title={title} rel='noopener'>
                    <Icon />
                </Link>
            );
        })}
        {children}
    </Field>
)``;