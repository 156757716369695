import React from 'react';
import styled from 'styled-components';

const NewsletterContainer = styled.div`
    background-color: #262626;
    margin: 100px 0px;
    padding: 30px 0px;
`;

const NewsletterTitle = styled.h2`
    color: #FFFFFF;
    text-align: center;
    font-size: 24px;
    margin: 30px 15px;
`;

const NewsletterFormContainer = styled.div``;
const Input = styled.input`
    margin-bottom: 30px;
    min-width: 620px;
    width: 100%;
    height: 35px;
    padding-left: 10px;
    font-size: 12px;
    box-shadow: none;
    -webkit-appearance: none;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-image: initial;
    border-radius: 0px;
    @media (max-width: 768px) {
        min-width: 290px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw / 3);
    margin: 0 auto;
`;
const Button = styled.button`
    font-size:14px;
    width: 290px;
    background-color: #89D5CB;
    margin-bottom: 15px;

    color: #262626;
    cursor: pointer;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    text-align: center;
    border: 0px;
    padding: 10px 50px;
    text-decoration: none;
    transition: all 0.2s linear 0s;

    &:hover {
        background-color: #36A29C;
    }
`;

export default () =>
    <NewsletterContainer>
        <NewsletterTitle>Subscribe for news and updates</NewsletterTitle>
        <NewsletterFormContainer>
            <Form
                className="js-cm-form"
                id="subForm"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1A56931036AB2E28B1826EACC02DBA572D43FEEC1BA410A52423DB4808662125BFD618E1AC128773114642F6C9D815E0C2"
            >
                <Input
                    autocomplete="Email"
                    aria-label="Email"
                    className="js-cm-email-input qa-input-email"
                    id="fieldEmail"
                    maxlength="200"
                    name="cm-jlthdul-jlthdul"
                    required=""
                    type="email"
                    placeholder="Enter your email..."
                />
                <Button type="submit">Subscribe</Button>
            </Form>
        </NewsletterFormContainer>
    </NewsletterContainer >