import React from 'react';
import styled from 'styled-components';
import { TextField, Select, ValueSetValidator } from '../../components/input';
import { serviceOptions } from '../../utils/constants';
import { DeleteButton } from '../../components/component-buttons';

const SubContainer = styled.div`
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    background-color:${props => [props.bgColor]};
    padding:${props => [props.padding]};
    border-bottom:1px solid #ffffff;

    @media(max-width:650px) {
        flex-direction:column;
    }
`;

const FormItem = styled.div`
    margin-bottom:15px;
    width:calc( (100% - 60px ) / 3 );
    margin-right:15px;

    @media(max-width:650px) {
        width:100%;
        margin-right:0px;
    }

    :nth-child(2n+0) {
        margin-left:15px;
        margin-right:15px;

        @media(max-width:650px) {
            margin-left:0;
            margin-right:0;
        }
    }

    :nth-child(3n+0) {
        margin-left:15px;
        margin-right:0px;

        @media(max-width:650px) {
            margin-left:0;
            margin-right:0;
        }
    }

    .Error {
        border:1px solid #990000;
        background-color:rgba(153,0,0,0.3);
    }
    span.Error {
        display:none;
        visibility:hidden;
    }

    input, textarea {
        width:100%;
        height:35px;
        padding-left:10px;
        font-size:12px;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:0px;
        box-shadow:none;
        -webkit-appearance: none;
    }
    select {
        width:100%;
        height:35px;
        font-size:12px;
    }
    textarea {
        height:200px;
        padding:10px;
        border:1px solid rgba(0,0,0,0.1);
        
    }
    :last-child {
    }
`;

const Label = styled.label`
    font-size:12px;
    line-height:26px;
    font-weight:600;
    margin-bottom:8px;
    text-transform:uppercase;
    display:block;
    
`;

export default class ServiceItem extends React.Component {

    onPropertyChange = ({target}) => {
        let { propertyId, index, onArrayItemChange, deleteArrayItem, ...props } = this.props;
        props[target.id] = target.value;
        onArrayItemChange(propertyId, props, index);
    }

    onDeleteItem = () => this.props.deleteArrayItem(this.props.propertyId, this.props.index)

    render() {
        const { type, mimumOrderLimit, maximumDistance } = this.props;
        return (
            <SubContainer bgColor="#f1f1f1" padding="15px">
                <FormItem>
                    <Label htmlFor={`type`}>type *</Label>
                    <Select
                        id="type"
                        value={type}
                        onChange={({ target }) => this.onPropertyChange({ target: { id: 'type', value: target.value } })}
                        options={serviceOptions.map(({ key, text }) => ({
                            key: key,
                            value: key,
                            text: `${text}`,
                        }))}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor={`mimumOrderLimit`}>mimum order limit *</Label>
                    <TextField
                        id={`mimumOrderLimit`}
                        value={mimumOrderLimit}
                        onChange={this.onPropertyChange}
                        placeholder=''
                        validator={new ValueSetValidator(this.validator)}
                    />
                </FormItem>
                <FormItem>
                    <Label htmlFor={`maximumDistance`}>maximum distance *</Label>
                    <TextField
                        id={`maximumDistance`}
                        value={maximumDistance}
                        onChange={this.onPropertyChange}
                        placeholder=''
                        validator={new ValueSetValidator(this.validator)}
                    />
                </FormItem>
                <DeleteButton onClick={this.onDeleteItem} />
            </SubContainer>
        )
    }
}