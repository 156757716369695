import React, { useContext } from 'react';
import styled from "styled-components";
import { Field } from './field';
import { getDescriptionOfList } from '../../utils/format';
import { CarIcon } from "../icons";
import { serviceOptions } from '../../utils/constants';
import { FieldCacheContext } from '.';

export const formatServicesOptionsDescription = (options = []) => {
    const methods = { 'delivery': 0, 'pick-up': 1, 'take-out': 2, 'drive-through': 3 };
    let types = options.map(option => option.type);
    types = types.sort((type1, type2) => (methods[type1] || 0) - (methods[type2] || 0));

    types = types.map(type => {
        let text = 'Other';
        serviceOptions.forEach(option => {
            if (option.key === type) {
                text = option.text;
            }
        });
        return text;
    });

    if (types.length > 0) {
        types[0] = types[0].slice(0, 1).toUpperCase() + types[0].slice(1);
    }
    return getDescriptionOfList(types, 'and');
}


export const BusinessServiceOptionsDescription = styled(({ className, id, children, options }) => {
    //Recover know fields from cache.
    const cache = useContext(FieldCacheContext) || {};
    if (!cache.formatServicesOptionsDescription) {
        cache.formatServicesOptionsDescription = formatServicesOptionsDescription(options);
    }

    return (
        <Field id={id} className={className}>
            <CarIcon />
            {cache.formatServicesOptionsDescription}
            {children}
        </Field>
    );
})``;