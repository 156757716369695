import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { borderColor } from '../../themes';

export const applyStyling = (component) => styled(component)`
    width:293px;
    background-color:#FFFFFF;
    text-decoration:none;
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    box-shadow:0px 3px 6px #00000029;
    margin-left:8px;
    margin-right:8px;
    margin-bottom:16px;
    border:1px solid ${borderColor};

    @media(max-width:480px) {
        width:100%;
        margin-bottom:45px;
        margin-left:0;
        margin-right:0;
    }
`;

const StyledLink = applyStyling(Link);

const CardContainer = ({ children, ...props }) => (
    <StyledLink {...props}>{children}</StyledLink>
)
export default CardContainer; 