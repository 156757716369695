import styled from 'styled-components';
import { mobileBoundryWidth } from '../../themes';

const top = ({searchEnabled, theme}) => theme.headerHeight + (searchEnabled ? (theme.searchBarHeight) : 0);
const topMobile = ({searchEnabled, theme}) => theme.headerHeightMobile + (searchEnabled ? (theme.searchBarHeightMobile) : 0);

const ScreensContainer = styled.div`
    position: relative;
    top: ${top}px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - ${top}px);

    transition: all 300ms;

    @media (max-width: ${mobileBoundryWidth}px ){
        top: ${topMobile}px;
        height: calc(100vh - ${topMobile}px); 
    }
`
export default ScreensContainer;