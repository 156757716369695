import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../components/icons';

export const CloseButton = styled( ({onClick, ...props}) => (
    <div onClick={ onClick } {...props} >
        <CloseIcon />
    </div>
))`
    cursor: pointer
`;
