import React from 'react';
import styled from "styled-components";
import Aside from '../aside';
import Footer from '../footer';
import { borderPadding, mobileBoundryWidth, borderPaddingMobile } from '../../themes';
import Preloader from '../preloader';

const Main = styled.main`
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    
    padding-top: ${borderPadding}px;

    @media (max-width: ${mobileBoundryWidth}px ){
        padding-top: ${borderPaddingMobile}px;   
    }

`;

const ScreenContainer = styled( ({children, showFooter=true, showAside=true, loading=false, ...props}) => {
    return (
        <Main {...props} >
            {loading ?
                <Preloader />
                :
                <> {children} </>
            }
            {showAside && <Aside />}
            {showFooter && <Footer />}
        </Main>
    );
})``;
export default ScreenContainer;