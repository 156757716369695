import { keyframes } from "styled-components";
import { useState } from "react";
import { headerHeight } from "../../themes";

//These are animation specific to this component.
const animateIn = keyframes`
    from { top: ${window.outerHeight}px; }
    to { top: ${headerHeight}px; }
`;

const animateOut = keyframes`
    from { top: ${headerHeight}px; }
    to { top: ${window.outerHeight}px; }
`;

export const useInOutAnimation = (onClosedAnimationCompleted) => {
    const [animation, setAnimation] = useState(animateIn);
    const close = () => {
        setAnimation(animateOut);
        setTimeout(() => (onClosedAnimationCompleted && onClosedAnimationCompleted()), 300);
    }
    return [animation, close, onClosedAnimationCompleted];
}