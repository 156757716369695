export const getDescriptionOfList = (list = [], verbalSeperator = 'or') => {
    const needsToVerbalSeperator = list.length > 1;
    return list.reduce((decription, item, index) => {
        const isLastItem = index === (list.length - 1);
        const seperator = (needsToVerbalSeperator && isLastItem) ? ` ${verbalSeperator} ` : ', ';
        return (decription ? decription + seperator : '') + item;
    }, null);
}

export const urlify = a => a.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "-").replace(/^-+|-+$/g, '')
export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
