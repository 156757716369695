import React from 'react';
import styled from "styled-components";
import BaseScreenContainer from '../../../components/screen-container';
import { 
    FieldCacheContext 
} from '../../../components/business-item-fields';
import { CloseButton } from './close-button';
import { borderPadding, mobileBoundryWidth, borderPaddingMobile } from '../../../themes';

export const Container = styled(BaseScreenContainer)`
    position: absolute;
    padding-top: 0px;
    background-color: white;
    animation: ${({animation}) => animation } 300ms ease-in-out forwards;
    
    ${CloseButton} {
        color: black;
        position: absolute;
        right: 0px;
        top: 0px;

        z-index: 1;
        
        padding:${borderPadding}px;

        @media (max-width: ${mobileBoundryWidth}px ) {
            padding:${borderPaddingMobile}px;
        }
    }
`;

export const ScreenContainer = styled( ({children, cache, ...props}) => {
    return (
        <FieldCacheContext.Provider value={cache} >
            <Container {...props}>
                {children}
            </Container>
        </FieldCacheContext.Provider>
    )
})``;