import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';

const PopUpContainer = styled.div`
    position: relative;
`;

const PopUpComponentContainer = styled.div`
    visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden' };
`;

export const ShowPopUp = (props) => {
    const [popupID] = useState(props.id || ('pop-up-' + Math.random().toString(36).substr(2, 9)));
    const [isOpen, setIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [timeoutReference, setTimeoutRefernce] = useState(false);

    const onSetVisibleTransitionTime = props.onSetVisibleTransitionTime || 1;
    const onSetHiddenTransitionTime = props.onSetHiddenTransitionTime || 300;

    const open = () => {
        clearTimeout(timeoutReference);
        setIsOpen(true);
        const reference = setTimeout(() => setVisible(true), onSetVisibleTransitionTime);
        setTimeoutRefernce(reference);
    }

    const close = () => {
        clearTimeout(timeoutReference);
        setIsOpen(false);
        const reference = setTimeout(() => setVisible(false), onSetHiddenTransitionTime);
        setTimeoutRefernce(reference);
    };
    const closeCallback = useCallback(close, [timeoutReference, onSetHiddenTransitionTime]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('click', closeCallback);
        }
        return () => {
            window.removeEventListener('click', closeCallback);
        }
    }, [isOpen, closeCallback]);

    const {children, PopUpComponent=( () => null), className} = props;
    return (
        <PopUpContainer 
            id={popupID} 
            className={className}
            onClick={ open }
        >
            {children}
            <PopUpComponentContainer isOpen={isOpen} >
                <PopUpComponent visible={visible} /> 
            </PopUpComponentContainer>
        </PopUpContainer>
    );
}