import React from 'react';
import { useInOutAnimation } from './animations';
import { useHistory, useParams } from 'react-router';
import { useBusinessItemData } from '../../utils/data';
import {
    ScreenContainer, 
    TypeColorIndicator,
    CloseButton,
    Header, 
    Menu, 
    Content,
} from './components';

const useBusinessPermalink = () => useParams().permalink;
const useDefaultBusiness = () => {
    const state = useHistory().location.state;
    if (!state?.id) {
        return null;
    } return state;
}

const ListingDetailScreen = () => {
    const history = useHistory();
    const [data, loading, error] = useBusinessItemData(useBusinessPermalink(), useDefaultBusiness());
    const [animation, onClose] = useInOutAnimation(() => history.push('/'));

    return (
        <ScreenContainer animation={animation} error={error} loading={loading} cache={data} >
            <TypeColorIndicator type={data.type} />
            <CloseButton onClick={onClose} />
            <Header
                logo={data?.logo}
                name={data?.name}
                type={ data?.type }
                contactOptions={ data?.contactOptions }
                operatingHours={ data?.operatingHours }
                serviceOptions={ data?.serviceOptions }
                paymentMethods={ data?.paymentMethods }
                locations={data?.locations}
            />
            <Menu menu={data?.menu} />
            <Content 
                aboutContent={[data?.aboutTitle, data?.aboutDescription]}
                orderContent={[data?.orderTitle, data?.orderDescription]}
                deliveryContent={[data?.deliveryTitle, data?.deliveryDescription]}
            />
        </ScreenContainer>
    )
};
export default ListingDetailScreen;