import React, { useState } from 'react';
import { routes } from './screens'
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { 
        lightTheme, 
        lightTheme as darkTheme, 
        GlobalStyle, 
        useMobileBoundry,
        useDarkModeQuery
} from './themes';
import Header from './components/header';
import { UserContext, MobileBoundryContext } from './utils/contexts';
import { useUser } from './utils/data';
import ScreensContainer from './components/screens-container';

function App() {
    const theme = useDarkModeQuery.matches ? darkTheme : lightTheme;
    const [user] = useUser();
    const isMobile = useMobileBoundry();
    const [searchEnabled, setSearchEnabled] = useState(false);
    return (
        <UserContext.Provider value={user}>
            <ThemeProvider theme={theme}>
                <MobileBoundryContext.Provider value={isMobile}>
                    <GlobalStyle />
                    <Router> 
                        <Header onSearchEnabled={ setSearchEnabled } />
                        <ScreensContainer searchEnabled={searchEnabled}>
                            <Switch>
                                { routes() }
                            </Switch>
                        </ScreensContainer>
                    </Router>
                </MobileBoundryContext.Provider>
            </ThemeProvider>

        </UserContext.Provider>
    );
}

export default App;
