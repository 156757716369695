import React, { useContext } from "react";
import styled from "styled-components";
import { FieldCacheContext } from ".";
import { Field } from "./field";
import { MarkerIcon } from "../icons";


const formatLocationsDescription = (locations = []) => {
    const defaultLocation = locations.find(location => location.isDefault) || locations[0] || {};
    const address = defaultLocation.address || '';
    return address + ((locations.length > 1) ? `(${locations.length} more)` : '');
}

export const BusinessLocations = styled(({ className, children, locations }) => {
    //Recover know fields from cache.
    const cache = useContext(FieldCacheContext) || {};
    if (!cache.formatLocationsDescription) {
        cache.formatLocationsDescription = formatLocationsDescription(locations);
    }
    
    return (
        <Field className={className}>
            <MarkerIcon />
            {cache.formatLocationsDescription}
            {children}
        </Field>
    );
})``;