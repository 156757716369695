import React from 'react';
import styled, { useTheme } from "styled-components";
import { SearchIcon, SlideHIcon, CloseIcon } from '../icons';
import { textColor, borderPaddingMobile, borderPadding, mobileBoundryWidth } from '../../themes';
import { default as BaseSearchBar } from '../search-bar';

export const LogoButton = styled( (props) => {
    const theme = useTheme();
    return <img src={theme.logo} {...props} alt='Mi Kier Traha' />
})`
    object-fit: contain;
`;

export const SearchButton = styled(SearchIcon)`
    color: ${textColor};
    &:hover {
        color:#89D5CB;
    }
    transition: color 300ms;
`;

export const FilterButton = styled(SlideHIcon)`
    color: ${textColor};
    &:hover {
        color:#89D5CB;
    }
    transition: color 300ms;
`;

export const SearchBar = styled( ({onClose, ...props}) => {
    return ( 
        <BaseSearchBar {...props}>
            <CloseIcon onClick={onClose}/>
        </BaseSearchBar>
    );        
})`
    position: relative;
    input {
        padding-right: 45px;
    }

    ${CloseIcon} {
        position: absolute;
        right: calc(${borderPadding}px + 10px);
        top: calc(50% - ${25/2}px);
        height: 25px !important;
        width: 25px !important;

        color: ${textColor};
        
        &:hover {
            color:#89D5CB;
        }
        transition: color 300ms;

        @media( max-width: ${mobileBoundryWidth}px ) {
            right: calc(${borderPaddingMobile}px + 10px);
        }
    }
`;