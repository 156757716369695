import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { borderColor, bodyColor } from '../../themes';
import { SocialMediaLinks, OtherLinks } from './components';
import Newsletter from './newsletter';

const Container = styled.aside`
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid ${borderColor};
    background-color: ${bodyColor};
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Item = styled.div`
    height:calc( 68px - 20px );
    margin-bottom:15px;

    @media (max-width: 480px) {
        margin-bottom:15px;
    }
`;

const Logo = styled.img`
    margin:0 auto;
    width:200px;
    @media (max-width: 768px) {
        width:125px;
    }
`;

const Aside = () => {
    return (
        <ThemeConsumer>
            {theme =>
                <Container>
                    <Newsletter></Newsletter>
                    <Item>
                        <Logo src={theme.logo} alt="Mi Kier Traha" />
                    </Item>
                    <SocialMediaLinks />
                    <OtherLinks />
                </Container>
            }
        </ThemeConsumer>
    );
}
export default Aside;
