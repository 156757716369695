import React from 'react';
import styled from 'styled-components';
import PreloaderImg from '../preloader/preloader.gif';

const Container = styled.div`
    padding: 0;
    width: auto;
    display:block;
`;

const PreloaderImageContainer = styled.span`
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    margin-bottom:60px;
    margin-top:0;
    min-height:calc( 100vh - calc( 80px + 210px ) );

    img {
        display:block;
        margin:0 auto;
        width:20px;
        height:20px;
    }
`;

const Preloader = () => {
    return (
        <Container>
            <PreloaderImageContainer>
                <img src={PreloaderImg} alt="load jobs" />
            </PreloaderImageContainer>
        </Container>
    );
}
export default Preloader;