import React, { useContext } from 'react';
import { 
    Container,
    EditButton,
    DeleteButton,
    BusinessStatus,
    BusinessLogo,
    BusinessName,
    BusinessOperatingHours,
    BusinessType,
    BusinessContactInfo,
    BusinessServiceOptionsDescription,
    BusinessCompactServiceOptions,
    BusinessPaymentMethodsDescription,
    BusinessCompactPaymentMethods,
    BusinessLocations,
    ViewMoreButton,
} from './components';
import { MobileBoundryContext } from '../../utils/contexts';

const BusinessItem = ({
    status,
    name,
    logo,
    type,
    contactOptions,
    operatingHours: hours,
    serviceOptions,
    paymentMethods,
    locations,
    onDeleteItem,
    onEditItem,
    onClickItem,
    canEditItem,
    canDeleteItem,
    cache
}) => {
    
    const isDesktop = !(useContext(MobileBoundryContext));
    const showEditButton = (isDesktop && canEditItem);
    const showDeleteButton = (isDesktop && canDeleteItem);

    return (
        <Container onClick={onClickItem} type={type} cache={cache} >
            {showEditButton && <>
                <EditButton onClick={onEditItem} /> 
            </>}
            {showDeleteButton && <>
                <DeleteButton onClick={onDeleteItem} />
            </>}

            <BusinessStatus status={status} />
            <BusinessLogo src={logo} alt={`${name}`} />
            <BusinessName>{name}</BusinessName>
            <BusinessOperatingHours hours={hours} />

            {isDesktop ? 
                <>
                    <BusinessType type={type} />
                    <BusinessContactInfo contactInfo={contactOptions} />
                    <BusinessServiceOptionsDescription options={serviceOptions} />
                    <BusinessPaymentMethodsDescription methods={paymentMethods} />
                    <BusinessLocations locations={locations} />
                    <ViewMoreButton onClick={onClickItem}>View More</ViewMoreButton>
                </>
                :
                <>
                    <BusinessCompactServiceOptions options={serviceOptions} />
                    <BusinessCompactPaymentMethods methods={paymentMethods} />
                </>
            }
        </Container>
    );
};
export default BusinessItem;