import React from 'react';
import styled from "styled-components";
import { mobileBoundryWidth } from '../../themes';
import { LogoButton, SearchBar } from './component-fields';
import { IconContainer, SVG } from '../icons';
import SearchResultsScreen from '../../screens/search-results-screen';

const THIN_BORDER = 14;
const contentHeight = (theme) => theme.headerHeightMobile - (2 * THIN_BORDER);
const searchBarTopMobile = (theme, enabled) => theme.headerHeightMobile - (enabled ? -1 : (theme.searchBarHeightMobile + 1));
const searchBarTop = (theme, enabled) => theme.headerHeight - (enabled ? -1 : (theme.searchBarHeight + 1));
const searchResultsTopMobile = (theme) => theme.headerHeightMobile + theme.searchBarHeightMobile;
const searchResultsTop = (theme) => theme.headerHeight + theme.searchBarHeight;

const mobileStyling = ({theme, searchEnabled}) => `
    position:fixed;
    z-index:1;

    height: ${theme.headerHeightMobile}px;
    width:100vw;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: ${theme.borderPaddingMobile}px;

    header-background {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-bottom:1px solid ${theme.borderColor};
        background-color:${theme.bodyColor};  
        z-index: -1;
    }

    ${LogoButton} {
        position: absolute;
        left: ${theme.borderPaddingMobile}px;
        margin-left: -3px; /*Remove padding set in image */
        height: ${contentHeight(theme)}px;
        width: auto;
    }

    ${IconContainer} {
        justify-self: flex-end;
        height: ${contentHeight(theme)}px;
        width: 35px; /* give the button extra width for easier tapping on mobile */
        padding: 2px;
        justify-content: flex-end; /* ...and align the icon content right */

        ${SVG} {
            width: ${contentHeight(theme)}px; /*...also make sure the icon is same as height */
        }
    }

    ${SearchBar} {
        position: absolute;
        left: 0px;
        top: ${searchBarTopMobile(theme, searchEnabled)}px;
        z-index: -2;
        transition: top 300ms;
    }

    ${SearchResultsScreen} {
        position: absolute;
        left: 0px;
        right: 0px;
        top: ${searchResultsTopMobile(theme)}px;
        height: 100vh;
        
        z-index: -2;

        background: ${theme.bodyColor};
    }
`;

const desktopStyling = ({theme, searchEnabled}) => `
    position:fixed;
    z-index:1;

    height: ${theme.headerHeight}px;
    width:100vw;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: ${theme.borderPadding}px;

    header-background {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-bottom:1px solid ${theme.borderColor};
        background-color:${theme.bodyColor};  
        z-index: -1;
    }

    ${LogoButton} {
        position: absolute;
        left: ${theme.borderPadding}px;
        margin-left: -3px; /*Remove padding set in image */
        height: ${contentHeight(theme)}px;
        width: auto;
    }

    ${IconContainer} {
        justify-self: flex-end;
        height: ${contentHeight(theme)}px;
        width: 35px; /* give the button extra width for easier tapping on mobile */
        padding: 2px;
        justify-content: flex-end; /* ...and align the icon content right */

        ${SVG} {
            width: ${contentHeight(theme)}px; /*...also make sure the icon is same as height */
        }
    }

    ${SearchBar} {
        position: absolute;
        left: 0px;
        top: ${searchBarTop(theme, searchEnabled)}px;
        z-index: -2;
        transition: top 300ms;
    }

    ${SearchResultsScreen} {
        position: absolute;
        left: 0px;
        right: 0px;
        top: ${searchResultsTop(theme)}px;
        height: 100vh;
        
        z-index: -2;

        background: ${theme.bodyColor};
    }
`;

const Header = styled.header``;
const ContainerContents = ({children, searchEnabled, ...props}) => {
    return (
        <Header searchEnabled={searchEnabled} {...props}>
            <header-background />
            {children}
        </Header>
    );
}

export const Container = styled(ContainerContents)` 
    
    @media (max-width: ${mobileBoundryWidth}px) {
        ${mobileStyling}
    }

    @media (min-width: ${mobileBoundryWidth}px) {
        ${desktopStyling}
    }
`;

