import React from 'react';
import { deleteBusiness } from '../../utils/data';
import BusinessListings from '../../components/business-listings';
import Preloader from '../../components/preloader';
import ScreenContainer from '../../components/screen-container';
import { UserContext } from '../../utils/contexts';
import { useHistory } from 'react-router';
import { useBusinessesData } from './custom-hooks';

const HomeScreen = () => {

    const history = useHistory();
    const [listings, loading, error] = useBusinessesData();

    return (
        <UserContext.Consumer>
            {user => 
                <ScreenContainer error={error} >
                    {loading ?
                        <Preloader />
                        :
                        <>
                            <BusinessListings 
                                listings={listings} 
                                canAddItem={ user?.claims?.role === 'admin' }
                                canDeleteItem={ user?.claims?.role === 'admin' }
                                canEditItem={ user?.claims?.role === 'admin' }
                                onEditItem={ business => 
                                    history.push(`/edit/${business.permalink}`, business) 
                                }
                                onClickItem={ business => {
                                    history.push(`/listing/${business.permalink}`, business) 
                                }}
                                onDeleteItem={ business => {
                                    if (window.confirm(`Are you sure you want to delete ${business.name}?`)) {
                                        deleteBusiness(business.id).onError( console.error )
                                    }
                                }}
                            />
                        </>
                    }
                </ScreenContainer>
            }
        </UserContext.Consumer>
    )
};
export default HomeScreen;