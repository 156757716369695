
import React from 'react';
import styled from "styled-components";
import { Field } from "../field";
import { Option } from "./option";
import { CheckIcon, TimesIcon, IconContainer } from '../../icons';

const Check = () => <CheckIcon data-testid='check' />;
const NotCheck = () => <TimesIcon data-testid='not-check' />;

export const CompactOptions = styled(({ className, id, children, options }) => {
    return (
        <Field id={id} className={className}>
            {options?.map( ({name, isCheck}, index) => 
                <React.Fragment key={index} >
                    <Option>{name}</Option>
                    {(isCheck === true) ? <Check /> : <NotCheck />}
                </React.Fragment>
            )}
            {children}
        </Field>
    );
})`
    ${IconContainer} {
        max-width: 10px;
        height: 10px;
        color: #262626;
        margin-right: 5px;
    }

    ${CheckIcon} {
        color: green;
    }

    ${TimesIcon} {
        color: red;
    }

    ${Option} {
        margin-top: 3px;
        margin-right: 2px;
    }
`;