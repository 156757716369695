import React, { useState } from 'react';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { escapeRegexCharacters } from '../../utils/format';

const InputContainer = styled.div`
    input {
        width:100%;
        height:35px;
        padding-left:10px;
        font-size:12px;
        border:1px solid rgba(0,0,0,0.1);
        border-radius:0px;
        box-shadow:none;
        -webkit-appearance: none;
    }

    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        height: 35px;
        padding-left: 10px;
        font-size: 12px;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 0px;
        box-shadow: none;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 31px;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }

`;

const NewItem = styled.span``;

const AddNewItem = value => <NewItem>+ Add {value}</NewItem>

const AutoSugguestInputField = ({ items, onSelected }) => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const onChange = (e, { newValue }) => setValue(newValue)
    const getSuggestionValue = ({ isNew, label }) => isNew ? value : label
    const renderSuggestion = ({ isNew, label }) => isNew ? AddNewItem(value) : label
    const onSuggestionsFetchRequested = ({ value }) => setSuggestions(getSuggestions(value))
    const onSuggestionsClearRequested = () => setSuggestions([])
    const onSuggestionSelected = (e, { suggestion }) => onSelected({value, ...suggestion})

    const getSuggestions = value => {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');
        const suggestions = items.filter(({ label }) => regex.test(label));
        return (suggestions.length === 0) ? [{ isNew: true }] : suggestions.slice(0, 10);
    }

    const inputProps = {
        placeholder: "Search tags...",
        value,
        onChange
    };
    return (
        <InputContainer>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
            />
        </InputContainer>
    )
}
export default AutoSugguestInputField;