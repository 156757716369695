import React, { useContext } from 'react';
import styled from 'styled-components';
import DetailHeader, {Image as BusinessLogo, Subtitle} from '../../../components/detail-header';
import { ContactOptions } from './contact-options';
import { mobileBoundryWidth } from '../../../themes';
import { 
    Field, 
    BusinessOperatingHours as OperatingHours,
    BusinessType as Type, 
    BusinessServiceOptionsDescription as ServiceOptions,
    BusinessPaymentMethodsDescription as PaymentMethods,
    BusinessLocations as Locations,
} from "../../../components/business-item-fields";
import { MobileBoundryContext } from '../../../utils/contexts';

const BaseHeader = styled(DetailHeader)`
    padding-left: 0px;
    padding-right: 0px;

    justify-content: space-evenly;

    ${BusinessLogo}, ${ContactOptions} {
        margin-bottom: ${({theme}) => 2 * theme.borderPadding}px;

        @media(max-width: ${mobileBoundryWidth}px ) {
            margin-bottom: ${({theme}) => 3 * theme.borderPaddingMobile}px;
        }
    }

    ${Field}, ${OperatingHours} {
        @media(max-width: ${mobileBoundryWidth}px ) {
            width: 100%;
        }   
    }

    ${Field} + ${Field} {
        @media(min-width: ${({theme}) => theme.mobileBoundryWidth - 1}px) {
            margin-left: 10px;
        }
    }

    title-type-container {
        @media(min-width: ${({theme}) => theme.mobileBoundryWidth - 1}px) {
            margin-bottom: ${({theme}) => 2 * theme.borderPadding}px;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            ${Subtitle} {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                ${ContactOptions} {
                    flex:0;
                    margin-bottom: 0px;
                    flex-basis: 37px;

                    max-width: 300px;
                }
            }
        }
    }
`;

const HeaderContainer = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;

    ${BaseHeader} {
        max-width: ${mobileBoundryWidth}px;
    }
`;

const Spacing = styled.div` 
    flex: 1;
`;

export const Header = styled(({
    logo, 
    name, 
    type, 
    onClose, 
    contactOptions, 
    operatingHours, 
    serviceOptions, 
    paymentMethods, 
    locations, 
    ...props
}) => {
    const isMobile = useContext(MobileBoundryContext);
    return (
        <HeaderContainer>
            <BaseHeader
                {...props}
                image={logo}
                title={name}
                subtitle={<>
                    <Type type={type} />
                    { !isMobile && <>
                        <Spacing />
                        <ContactOptions options={contactOptions} /> 
                    </>}
                </>}
                onClose={onClose}
            >
                {isMobile && <>
                    <ContactOptions options={contactOptions} /> 
                </>}
                <OperatingHours hours={operatingHours} />
                <ServiceOptions options={serviceOptions} />
                <PaymentMethods methods={paymentMethods} />
                <Locations locations={locations} />
            </BaseHeader>
        </HeaderContainer>
    );
})``;