import { useContext, useCallback} from 'react';
import {useBusinessesData as _useBusinessesData} from '../../utils/data';
import { UserContext } from '../../utils/contexts';
import { WEEKDAYS } from '../../utils/constants';


const createRangeFinder = (date=new Date()) => {
    const hours = (date.getHours() * 100) + date.getMinutes();
    const weekday = WEEKDAYS[ date.getDay() === 0 ? 6 : (date.getDay() - 1) ];
    return (range) => {
        if (range.weekday === weekday && range.from && range.to) {
            const fromColonPosition = range.from.indexOf(':');
            const fromAsInt = parseInt(`${range.from.slice(0, fromColonPosition)}${range.from.slice(fromColonPosition + 1,5)}`);
            const toColonPosition = range.to.indexOf(':');
            const toAsInt = parseInt(`${range.to.slice(0,toColonPosition)}${range.to.slice(toColonPosition + 1,5)}`);
            if (fromAsInt <= hours && toAsInt >= hours) {
                return true;
            }
        }
        return false;
    };
};

const isOpen = (business, rangeFinder) => {
    return Boolean( business.operatingHours.find( rangeFinder ));
}

export const useBusinessesData = () => {
    const adminQuery = useCallback((ref) => ref.orderBy('name', 'asc'), []);
    const userQuery = useCallback((ref) => ref.where('status', '==', 'published').orderBy('name', 'asc'), []);
    
    const user = useContext(UserContext);
    const isAdmin = user?.claims?.role === 'admin';
    const results = _useBusinessesData(isAdmin ? adminQuery : userQuery);

    const isOpenCache = new Map();
    const rangeFinder = createRangeFinder();
    //Sort the list according to open businesses first, closed onces at the bottom, then alphabetically.
    results[0] = results[0].sort( (business, otherBusiness) => {
        let businessOpen = isOpenCache.get(business.id);
        if (businessOpen === undefined) {
            businessOpen = isOpen(business, rangeFinder);
            isOpenCache.set(business.id, businessOpen);
        }

        let otherBusinessOpen = isOpenCache.get(otherBusiness.id);
        if (otherBusinessOpen === undefined) {
            otherBusinessOpen = isOpen(otherBusiness, rangeFinder);
            isOpenCache.set(otherBusiness.id, otherBusinessOpen);
        }
        
        if (businessOpen && otherBusinessOpen) {
            //sort based on name
            return businessOpen?.name?.localCompare(otherBusiness?.name);
        }
        else if (businessOpen && !otherBusinessOpen) {
            //Open business goes to top.
            return -1;
        }
        //Closed business goes to bottom.
        return 1;
    } );
    
    return results;
}