import React from 'react';
import styled from "styled-components";
import { mobileBoundryWidth } from '../../themes';

const ContentBlockTitle = styled.h3`
    font-size: 20px;
    text-align: center;
    max-width: ${mobileBoundryWidth}px;

    @media (max-width: ${mobileBoundryWidth}px) {
        text-align: left;
    }
`;

const ContentBlockDescription = styled.p`
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
    max-width: ${mobileBoundryWidth}px;

    @media (max-width: ${mobileBoundryWidth}px) {
        text-align: left;
    }
`;

const ContentBlockComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;  

    @media (max-width: ${mobileBoundryWidth}px) {
        align-items: flex-start;  
    }
`;

const BlockSection = styled(({ title, children, ...props }) => {
    return (
        <ContentBlockComponentContainer {...props}>
            {title?.length > 0 && <>
                <ContentBlockTitle>{title}</ContentBlockTitle>
            </>}
            {children}
        </ContentBlockComponentContainer>
    )
})``;

export const ContentBlockComponent = styled(({ description, ...props }) => {
    return (
        <BlockSection {...props} >
            <ContentBlockDescription>{description}</ContentBlockDescription>
        </BlockSection>
    );
})``;

export const ContentBlockGallery = styled.div``;

export const GalleryBlockComponent = styled(({ images = [], children, ...props }) => {
    return (
        <BlockSection {...props} >
            <ContentBlockGallery images={images} />
        </BlockSection>
    )
})``;
