import styled from "styled-components";
import { mobileBoundryWidth } from "../../../themes";

export const BusinessName = styled.span`
    font: Bold 16px/24px Roboto;
    text-align: center;

    @media (max-width: ${mobileBoundryWidth}px) {
        font: Bold 14px/21px Roboto;
        text-align: left;
    }
`;