
import algoliasearch from 'algoliasearch/lite';

var _algolia = null;
export const getAlgolia = () => {
    if (!_algolia) {
        _algolia = algoliasearch(
            'XO2XCP0O9I',
            '87c3c6ac3ec814626f69cfd93c532d52'
          );
    }
    return _algolia;
}