import React, { useEffect } from 'react';
import BusinessListings from '../../components/business-listings';
import { useHistory } from 'react-router';
import { useBusinessSearch } from '../../utils/search';
import { useHeader } from '../../components/header';
import styled from 'styled-components';
import { ScreenContainer } from './component-containers';
import Preloader from '../../components/preloader';
import { UserContext } from '../../utils/contexts';
import { deleteBusiness } from '../../utils/data';

const SearchResults = styled(({ onClickItem, ...props }) => {
    const history = useHistory();
    const [listings, search, loading] = useBusinessSearch();
    const header = useHeader();

    useEffect(() => {
        //Do the initial search.
        search(header.state.searchValue);
        return header.onHeaderStateChanged(
            ({ searchValue }) => search(searchValue)
        );
    }, [header, search]);

    return (
        <UserContext.Consumer>
            {user => 
                <ScreenContainer {...props}>
                    {loading ?
                        <Preloader />
                        :
                        <BusinessListings
                            listings={listings}
                            canAddItem={ user?.claims?.role === 'admin' }
                            canDeleteItem={ user?.claims?.role === 'admin' }
                            canEditItem={ user?.claims?.role === 'admin' }
                            onEditItem={ business => 
                                history.push(`/edit/${business.permalink}`, business) 
                            }
                            onClickItem={business => {
                                history.push(`/listing/${business.permalink}`, business);
                                header.dismissSearchBar();
                            }}
                            onDeleteItem={ business => {
                                if (window.confirm(`Are you sure you want to delete ${business.name}?`)) {
                                    deleteBusiness(business.id).onError( console.error )
                                }
                            }}
                        />
                    }

                </ScreenContainer>
        }
        </UserContext.Consumer>
    )
})``;
export default SearchResults;