import React from 'react';
import styled from "styled-components"
import { lightTextColor } from '../../../themes';

const Container = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Link = styled.a` 
    text-decoration: none;
    color: ${lightTextColor};
    opacity: 0.5;
    font: normal 14px/24px Roboto;
    
    :visited { 
        text-decoration: none; 
    }
`;

export const OtherLinks = () => {
    
    return (
        <Container>
            <Link href='https://form.typeform.com/to/xeOlZQ' target="__blank">Advertise with us</Link>
        </Container>
    )
}